import { ILitigationFileContentRequest } from "../../../../Models/ILitigationCaseRequest";
import { LitigationContext } from "../ChatHome";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import DisplaySpinner from "../../../../Common/Components/DisplaySpinner";
import { getIconByFileExtension, truncateText } from "../../../../Helpers/ProjectHelper";
import { ISource } from "../../../../Models/Chat/ISource";
import './MessageSources.styles.css'
import React, { useEffect, useRef, useState } from "react";
import { ITooltipHostStyles, Stack, TooltipHost, Icon } from "@fluentui/react";
import { ProjectAPI } from "../../../../Services/ProjectAPI";
import { IRequestLitiagtionFileURL } from "../../../../Shared/Actions/Project.action-types";
import { ILitigationFileContentResponses } from "../../../../Models/ILitigationCase";
const toolTipStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
import { v4 as uuidv4 } from "uuid";
import { setContext } from "redux-saga/effects";
import { requestLitigationFileContent, requestLitigationFileURL } from "../../../../../src/Shared/Actions/Project.action";
import FileContentDialog from "../../Project/CreateProject/CaseDocumentsReview/FileContentDialog";
import './OpenFile.styles.css'

type IOpenFileProps = {
  source: ISource
}

export default function OpenFile(openFileProps: IOpenFileProps) {
  const { source } = openFileProps
  const { isChatExpanded } = React.useContext(LitigationContext);
  const calloutProps = { gapSpace: 0 };
  const { user } = React.useContext(LitigationContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const { useSelector, dispatch } = reduxContext;
  const { litCases } = React.useContext(LitigationContext);
  const [contextMenu, setContextMenu] = useState(null);
  const { httpClient } = reduxContext;
  const spanRef = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [litigationFileExists, setLitigationFileExists] = useState<boolean>(true)
  const [litigationWebUrl, setLitigationWebUrl] = useState<ILitigationFileContentResponses>({})
  const [toolTipContent, setToolTipContent] = useState<string>('')
  const [hideChatFileDialog, setHideChatFileDialog] = React.useState(true);
  const [nativeChatFilePath, setNativeChatFilePath] = useState<string>('')
  const openFile = async (sourceUrl: string, nativeFileName?: string) => {
    try {
      if (!source?.custodianCase && litCases) {
        throw new Error("No case information is available");
      }
      if (sourceUrl?.length===0) {
        throw new Error("No native file information is available");
      }
      //setIsLoading(true)
      if (sourceUrl !== '' && nativeFileName !== '') {

        setHideChatFileDialog(false);
        let nativePath = combinePaths(nativeFileName, sourceUrl);
        setNativeChatFilePath(nativePath);
        const litigationFileContentRequest: ILitigationFileContentRequest = {
          userAlias: user?.secondaryText,
          fileName: '',
          fileId: source.filepath,
          caseInfo: source?.custodianCase,
          isKeywordSearch: false,
          nativeFilePath: nativePath
        }

        dispatch(requestLitigationFileContent(litigationFileContentRequest));
        dispatch(requestLitigationFileURL(litigationFileContentRequest));

        // const litigationFileUrl: IRequestLitiagtionFileURL = {
        //   litigationFileURLRequest: litigationFileContentRequest
        // }
        // const correlationUUID = uuidv4();
        // const litigationWebUrl: ILitigationFileContentResponses = await ProjectAPI.getLitigationFileURL(litigationFileUrl, httpClient, correlationUUID);
        // setLitigationWebUrl(litigationWebUrl)
        // if (litigationWebUrl?.embedURL !== '') {
        //   setLitigationFileExists(true)
        // }
        // else {
        //   setToolTipContent("File does not exists")
        //   setLitigationFileExists(false)
        // }
        // setIsLoading(false)
      }
    }
    catch (error: any) {
      setToolTipContent(error?.message ?? "File does not exists")
      setLitigationWebUrl({})
      setIsLoading(false)
      setLitigationFileExists(false)
    }
  }

  const openFileClickHandler = async (sourceUrl: string, nativeFileName?: string) => {
    if (sourceUrl && nativeFileName) {
      await openFile(sourceUrl, nativeFileName)
    }
    else {
      if (sourceUrl.includes('?')) {
        // Parameters already exist, append &web=1
        sourceUrl += '&web=1';
      } else {
        // No parameters exist, append ?web=1
        sourceUrl += '?web=1';
      }
      await openProjectFile(sourceUrl)
    }
  }

  const openProjectFile = (sourceUrl: string) => {
    window.open(`${sourceUrl}?web=1`, '_blank');
  }
  const openFileKeydownHandler = async (event: any, sourceUrl: string, nativeFileName?: string) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (litCases) {
        await openFile(sourceUrl, nativeFileName)
      }
      else {
        if (sourceUrl.includes('?')) {
          // Parameters already exist, append &web=1
          sourceUrl += '&web=1';
        } else {
          // No parameters exist, append ?web=1
          sourceUrl += '?web=1';
        }
        await openProjectFile(sourceUrl)
      }
    }
  }

  // useEffect(() => {
  //   if (litigationWebUrl?.embedURL) {
  //     window.open(litigationWebUrl?.embedURL)
  //   }
  // }, [litigationWebUrl])


  function combinePaths(metadataStoragePath: string, exportNativePath: string): string {
    // Step 1: Find the index of '.net/' in metadataStoragePath
    const baseIndex = metadataStoragePath.indexOf('.net/') + 5; // Length of '.net/' is 5

    if (baseIndex === 4) {
      console.error('Failed to find .net/ in metadataStoragePath.');
      return '';
    }

    // Find the index of the next '/' after the baseIndex
    const nextSlashIndex = metadataStoragePath.indexOf('/', baseIndex);
    if (nextSlashIndex === -1) {
      console.error('No slash found after .net/ in metadataStoragePath.');
      return '';
    }

    // Find the next '/' to extract the base URL up to and including the container name and litigation ID
    const subsequentSlashIndex = metadataStoragePath.indexOf('/', nextSlashIndex + 1);
    if (subsequentSlashIndex === -1) {
      console.error('Failed to find the next slash after container name and litigation ID.');
      return '';
    }

    // Extract the base URL up to and including the container name and litigation ID
    const baseURL = metadataStoragePath.substring(0, subsequentSlashIndex + 1);

    // Step 2: Combine the base URL with exportNativePath
    const filePath = `${baseURL}${exportNativePath.startsWith('/') ? exportNativePath.slice(1) : exportNativePath}`;

    // Step 3: Return the combined file path
    return filePath;
  }
  const copyToClipBoard = (content?: string) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.error("Copied the text to clip board");
        setToolTipContent("Url copied to clip board")
      })
      .catch((error) => {
        setToolTipContent("Error copying to clipboard")
        console.error("Error copying to clipboard:", error);
      });
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    })

  }

  const handleCopyClick = () => {
    if (spanRef?.current) {
      copyToClipBoard(source?.documentTitle)
    }
  }

  const handleClick = () => {
    setContextMenu(null);
  };

  const handleCloseChatFileDialog = () => {
    setHideChatFileDialog(true);
  };


  useEffect(() => {    
    setIsExpanded(isChatExpanded)
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {    
    setIsExpanded(isChatExpanded)    
  }, [isChatExpanded]);
  return (
    <>

      <FileContentDialog
        isOpen={!hideChatFileDialog}
        title={""}
        onDismiss={handleCloseChatFileDialog}
        dialogWidth="98vw"
        dialogHeight="98vh"
        nativeFilePath={nativeChatFilePath}
      />
      <Stack horizontal tokens={{ childrenGap: 5 }} style={{ marginTop: -20 }}>
        <Stack>
          <Stack className='file-icon-container'>
            <Icon
              iconName={getIconByFileExtension(source?.documentTitle ?? source?.sourceUrl)}
              role="icon"
              aria-label={source?.sourceUrl}
              className='file-icon'
            />
          </Stack>
          <Stack className='file-name'>
            {/* <TooltipHost
              content={`${source?.documentTitle}`}
              calloutProps={calloutProps}
              styles={toolTipStyles}
              setAriaDescribedBy={true}
              aria-label={` ${source?.documentTitle}`}
            > */}
            <Stack className='file-link' style={{ position: 'relative' }}>
              <span ref={spanRef} onContextMenu={handleContextMenu} tabIndex={0}
                onClick={() => openFileClickHandler(source?.sourceUrl, source?.documentTitle)}
                onKeyDown={(e) => openFileKeydownHandler(e, source?.sourceUrl, source?.documentTitle)} 
                className={isExpanded?"expanded":'not-expanded'} >
                {source?.documentTitle.split('/').pop()}
              </span>
              {contextMenu && (
                <div
                  style={{
                    backgroundColor: 'white',
                    zIndex: 1000000,
                    marginLeft: 220,
                    marginTop: -20
                  }}
                >
                  <button onClick={handleCopyClick} tabIndex={0}>Copy</button>
                </div>
              )}
            </Stack>
            {/* </TooltipHost> */}
          </Stack>
        </Stack>
        <Stack>
          {isLoading && (
            <Stack style={{ marginTop: -178 }}>
              <DisplaySpinner spinnerText="" accessabilityMessage="Opening file..." spinnerPosition={"right"} />
            </Stack>
          )}
          {!isLoading && (litigationFileExists === false) && (
            <Stack style={{ marginTop: 22 }} tabIndex={0}>
              <TooltipHost content={toolTipContent}>
                <Icon iconName="info" />
              </TooltipHost>
            </Stack>
          )}

        </Stack>
      </Stack>
    </>
  )
}
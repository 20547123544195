import { Icon, MessageBarType, SearchBox, Spinner, SpinnerSize, Stack, TooltipHost } from "@fluentui/react";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import DisplaySpinner from "../../../Common/Components/DisplaySpinner";
import { GenericMessages, ProjectEventNames } from "../../../Helpers/ProjectConstants";
import { ProjectListFilter, ProjectListType } from "../../../Helpers/ProjectEnum";
import { clearMessageState, requestDeleteProject, requestProjectDetails, requestProjectListDetailV2, setProjectContext, setProjectFilterListDetails } from "../../../Shared/Actions/Project.action";
import { IProjectAppState } from "../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { columnCell, columnCell1, columnCell2, displayMessage, divGridCSS, spinnerCss, spinnerDeleteCss, statusIcon, statusIcon2, statusIconGreen, statusIconGreen2, statusTextClose, statusTextOpen } from "../Styles/ProjectList";
import { IProjectListResponse } from "../../../Models/IProjectListResponse";
import MemberView from "../../Projects/Components/MemberView";
import { FormEvent } from "react";
import { IProjectListDetails } from "../../../Models/IProjectListDetails";
import DisplayMessage from "../../../Common/Components/DisplayMessage";
import { useHistory, useLocation } from "react-router-dom";
import { IProjectContext } from "../../../Models/IProjectContext";
import ErrorDisplay from "../../../Common/Components/ErrorDisplay";
import { getFormattedDate, truncateText } from "../../../Helpers/ProjectHelper";
import { FeatureFlag } from "../../FeatureFlag/FeatureFlag";
import useGraphToken from "../../Hooks/useGraphToken";
import { app } from "@microsoft/teams-js";
import { ITabNavigation } from "../../../Models/TabNavigation/ITabNavigation";
import "../Styles/ProjectListsPageStyles.css"
import ProjectOptionsMenu from "./ProjectOptionsMenu";
import View from "../History/View";
export const clipBoardIconStyle: React.CSSProperties = {
    left: "16px",
    mixBlendMode: "normal",
    fontSize: "18px",
    cursor: "pointer",
    marginLeft: 5,
    color: "rgb(0, 120, 212"
};
const ProjectsListPage = () => {
    let _selection: Selection;
    let history = useHistory();
    let location = useLocation();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const {
        projectProps,
        projectListDetails,
        projectListFilterDetails,
        isProjectListLoading,
        isProjectListLoadingError,
        projectListError,
        loginUserpersonaProps,
        projectSearchRequest,
        isProjectGettingCreated,
        projectCreationSuccess,
        projectCreationResponse,
        isProjectDeleting,
        isProjectDeletingError,
        projectDeleteSuccess,
        featureFlagInformation,
        isLoadingFeatureFlagInformation,
        loadingLoginUserProfile,
        isloadingLoginUserProfileError,
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    const [selectedRecentFilter, updateSelectedRecentFilter] = React.useState("recent-my-projects-btn");
    const [tempProjectList, settempProjectList] = React.useState<IProjectListResponse[]>();
    const [fetchingProjectToEdit, setFetchingProjectToEdit] = React.useState(false);
    const [detailListColumns, setDetailListColumns] = React.useState<IColumn[]>();
    const [selectedList, setSelectedList] = React.useState("");
    const [selectedCount, setselectedCount] = React.useState(0);
    const [isOwner, setOwner] = React.useState(true);
    const [isDeleteGridLoading, setDeleteGridLoading] = React.useState(false);
    const [showAccessError, setShowAccessError] = React.useState(false);
    const [isNavigationFromDashboard, setIsNavigationFromDashboard] = React.useState(false);
    const { graphToken } = useGraphToken();
    const [tabNavigation, setTabNavigation] = React.useState<ITabNavigation>({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const recordsPerPage = 10;

    let spinnerText = '';
    if (selectedRecentFilter === "recent-teams-projects-btn") {
        spinnerText = "Loading my team projects...";
    }
    else if (selectedRecentFilter === "recent-my-projects-btn") {
        spinnerText = "Loading my projects...";
    }
    else {
        spinnerText = "Loading projects...";
    }
    // ComponentWillMount
    React.useEffect(() => {
        app?.getContext().then((context) => {
            if (context?.page?.subPageId !== '') {
                setTabNavigation(JSON.parse(context.page.subPageId));
            }
        });
    }, []);

    // ComponentWillUnmount
    React.useEffect(() => {
        return () => {
            // Clear all messages before unmounting the page
            dispatch(clearMessageState());
        }
    }, []);

    React.useEffect(() => {
        if (loginUserpersonaProps && loginUserpersonaProps?.optionalText) {
            switch (getProjectListType()) {
                case ProjectListType.BOOKMARKS:
                    projectSearchRequest.owner = loginUserpersonaProps?.secondaryText;
                    dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, projectSearchRequest, ProjectListType.BOOKMARKS, ProjectListFilter.ALL, currentPage, recordsPerPage));
                    break;
                case ProjectListType.HISTORY:
                    projectSearchRequest.owner = loginUserpersonaProps?.secondaryText;
                    dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, projectSearchRequest, ProjectListType.HISTORY, ProjectListFilter.ALL, currentPage, recordsPerPage));
                    break;
                default:
                    projectSearchRequest.owner = loginUserpersonaProps?.secondaryText
                    dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, projectSearchRequest, ProjectListType.DEFAULT, ProjectListFilter.ALL, currentPage, recordsPerPage));
                    break;
            }
        }
    }, [loginUserpersonaProps, location.pathname]);


    React.useEffect(() => {
        if (projectListDetails?.items?.length > 0) {
            settempProjectList(projectListDetails.items);
            if (selectedRecentFilter === 'recent-teams-projects-btn') {
                setDetailListColumns(columnsTeam);
            }
            else {
                setDetailListColumns(columns);
            }
        }
    }, [projectListDetails]);

    React.useEffect(() => {
        if (projectDeleteSuccess) {
            projectSearchRequest.owner = loginUserpersonaProps?.secondaryText
            dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, projectSearchRequest, ProjectListType.DEFAULT, ProjectListFilter.ALL, currentPage, recordsPerPage));
            setDeleteGridLoading(false);
        }
    }, [projectDeleteSuccess]);

    // Trigger redirect to Edit project when project details are available
    React.useEffect(() => {
        if (fetchingProjectToEdit) {
            setFetchingProjectToEdit(false);
            history.push(`/createproject?projectNumber=${projectProps.number}`);
        }
    }, [projectProps])

    function loadFilteredData(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        setDeleteGridLoading(false);

        const newList = newValue ? tempProjectList?.filter(i => i.title?.toLowerCase().indexOf(newValue) > -1) : projectListDetails.items;
        const newFilteredList: IProjectListDetails = {
            items: []
        };

        Object.keys(newList).map((object) => {
            const projectProps: IProjectListResponse = { title: "", description: "", number: "", status: "", startDate: "", endDate: "", members: [], owners: [] };
            let props_object = newList[object];
            projectProps.description = props_object?.description;
            projectProps.title = props_object?.title;
            projectProps.number = props_object?.number;
            projectProps.startDate = props_object?.startDate;
            projectProps.endDate = props_object?.endDate;
            projectProps.status = props_object?.status;
            projectProps.members = props_object?.members;
            projectProps.owners = props_object?.owners;
            newFilteredList.items.push(projectProps);
        });
        settempProjectList(newFilteredList.items);
        dispatch(setProjectFilterListDetails(newFilteredList.items));
    }


    const handleFilterSubmit = (filterValues) => {
        setDeleteGridLoading(false);

        const newList = projectListDetails?.items
            .filter(i => (!filterValues.status || filterValues.status === 'All' || i.status === filterValues.status))
            .filter(i => (!filterValues.owneremail || i.owners.includes(filterValues.owneremail)))
            .filter(i => (!filterValues.startDate || new Date(i.startDate) >= new Date(filterValues.startDate)))
            .filter(i => (!filterValues.endDate || new Date(i.endDate) <= new Date(filterValues.endDate)));


        const newFilteredList: IProjectListDetails = {
            items: []
        };

        Object.keys(newList).map((object) => {
            const projectProps: IProjectListResponse = { title: "", description: "", number: "", status: "", startDate: "", endDate: "", members: [], owners: [] };
            let props_object = newList[object];
            projectProps.description = props_object?.description;
            projectProps.title = props_object?.title;
            projectProps.number = props_object?.number;
            projectProps.startDate = props_object?.startDate;
            projectProps.endDate = props_object?.endDate;
            projectProps.status = props_object?.status;
            projectProps.members = props_object?.members;
            projectProps.owners = props_object?.owners;
            newFilteredList.items.push(projectProps);
        });
        settempProjectList(newFilteredList.items);
        dispatch(setProjectFilterListDetails(newFilteredList.items));
    };

    function getProjectListType(): ProjectListType {
        if (location.pathname.includes("bookmarks")) {
            return ProjectListType.BOOKMARKS;
        }

        if (location.pathname.includes("history")) {
            return ProjectListType.HISTORY;
        }

        return ProjectListType.DEFAULT;
    }

    const handleDelete = (projectNumber: string) => {
        if (isOwner) {
            setDeleteGridLoading(true);
            setShowAccessError(false);
            dispatch(setProjectFilterListDetails(null));
            dispatch(requestDeleteProject(projectNumber));
            clearVariables();
        }

        else {
            setShowAccessError(true);
        }
    }

    const handleEdit = (projectNumber: string) => {
        // history.push(`/viewproject?projectNumber=${projectNumber}&backToProjectList=true`);       
        if (isOwner) {
            const prjInContext: IProjectContext = {
                number: projectNumber
            }
            dispatch(setProjectContext(prjInContext));
            dispatch(requestProjectDetails(prjInContext, loginUserpersonaProps?.secondaryText));
            setFetchingProjectToEdit(true);
        }
        else {
            setShowAccessError(true);
        }
    }


    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = _copyAndSort(projectListFilterDetails, currColumn.fieldName!, currColumn.isSortedDescending);

        setDetailListColumns(newColumns);
        settempProjectList(newItems);
        dispatch(setProjectFilterListDetails(newItems));

    }
    const opeSPOUrl = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const columns: IColumn[] = [

        {
            key: 'column1',
            name: 'Project name',
            fieldName: 'title',
            minWidth: 250,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            isResizable: true,
            onRender: (item: IProjectListResponse) => {
                return (
                    <Stack>
                        <Stack
                            onClick={(e) => openProjectURLUrl(e, item.number)}
                            className="column-clickable-cell"
                        ><TooltipHost content={item.title}>
                                {item.title?.trim() ? (
                                    truncateText(item.title.trim(), 35, 35)
                                ) : (
                                    item.number
                                )}
                            </TooltipHost>
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            key: 'column2',
            name: 'Start date',
            fieldName: 'startDate',
            minWidth: 150,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    marginLeft: 75,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: 'date',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item) => {
                return (
                    <>
                        <Stack className="column-clickable-cell" >
                            <Stack horizontal>
                                <View item={item} />
                                {item?.title?.toLowerCase() !== 'project not available' && item?.spoUrl && (<Icon
                                    onClick={() => opeSPOUrl(item.spoUrl)}
                                    data-testid="copy-icon"
                                    iconName="Link"
                                    style={clipBoardIconStyle}
                                    aria-label="click on this icon to copy the spo url to clipboard"
                                />)}
                            </Stack>
                        </Stack>
                        <Stack className="column-cell" style={{ marginLeft: 75, marginTop: -20 }}>
                            {item.startDate
                                ? getFormattedDate(item.startDate?.trim())
                                : item.startDate?.trim()}
                        </Stack>
                    </>
                );
            },
        },
        {
            key: 'column3',
            name: 'Description',
            fieldName: 'description',
            minWidth: 250,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: IProjectListResponse) => {
                return (
                    <Stack className="column-cell" style={{ marginTop: 0 }}>
                        <TooltipHost content={item.description}>
                            {truncateText(item.description?.trim(), 35, 35)}
                        </TooltipHost>
                    </Stack>
                );
            },
        },
        {
            key: 'column4',
            name: 'Last modified date',
            fieldName: 'lastModifiedDate',
            minWidth: 150,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    marginLeft: 75,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: 'date',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item) => {
                return (
                    <>

                        <Stack className="column-cell" style={{ marginLeft: 75, marginTop: 0 }}>
                            {item.lastModifiedDate
                                ? getFormattedDate(item.lastModifiedDate?.trim())
                                : item.lastModifiedDate?.trim()}
                        </Stack>
                    </>
                );
            },
        },
        {
            key: 'column5',
            name: 'Status',
            fieldName: 'status',
            minWidth: 75,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: IProjectListResponse) => {
                if (item.status?.toLowerCase() === 'open') {
                    return (
                        <Stack horizontal style={columnCell}>
                            <Stack>
                                <Icon
                                    iconName={'StatusCircleRing'}
                                    style={statusIconGreen}
                                />
                            </Stack>
                            <Stack>
                                <Icon
                                    iconName={'StatusCircleCheckmark'}
                                    style={statusIconGreen2}
                                />
                            </Stack>
                            <Stack style={statusTextOpen}>{item.status?.trim()}</Stack>
                        </Stack>
                    );
                } else {
                    return (
                        <>
                            {item.status?.trim().length > 0 && (
                                <Stack horizontal style={columnCell}>
                                    <Stack>
                                        <Icon
                                            iconName={'StatusCircleRing'}
                                            style={statusIcon}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Icon
                                            iconName={'StatusCircleBlock2'}
                                            style={statusIcon2}
                                        />
                                    </Stack>
                                    <Stack style={statusTextClose}>{item.status?.trim()}</Stack>
                                </Stack>
                            )}
                        </>
                    );
                }
            },
        },
        {
            key: 'column6',
            name: 'Team members',
            fieldName: 'members',
            minWidth: 250,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: IProjectListResponse) => {
                const count = item?.members?.length;
                return (
                    <Stack>
                        {item?.members && count > 1 ? (
                            <Stack
                                horizontal
                                onClick={(e) => openProjectURLUrl(e, item.number)}
                                className="column-clickable-cell"
                            >
                                <Stack className="column-cell">
                                    <MemberView userAlias={item.members[0]} graphTokenParam={graphToken} />
                                </Stack>
                                <Stack style={columnCell2}>
                                    <Stack>+ {count - 1}</Stack>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack style={columnCell1}>
                                <MemberView userAlias={item?.members?.length > 0 ? item.members[0] : ''} graphTokenParam={graphToken} />
                            </Stack>
                        )}
                    </Stack>
                );
            },
        },
        {
            key: 'column7',
            name: 'Owner',
            fieldName: 'owner',
            minWidth: 250,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item) => {
                const count = item?.owners?.length;
                return (
                    <Stack horizontal>
                        {item?.owners && count > 1 ? (
                            <Stack
                                horizontal
                                onClick={(e) => openProjectURLUrl(e, item.number)}
                                className="column-clickable-cell"
                            >
                                <Stack style={columnCell1}>

                                    <MemberView userAlias={item.owners[0]} graphTokenParam={graphToken} />

                                </Stack>
                                <Stack style={columnCell2}>
                                    <Stack>+ {count - 1}</Stack>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack style={columnCell1}>
                                <MemberView userAlias={item?.owners?.length > 0 ? item.owners[0] : ''} graphTokenParam={graphToken} />
                            </Stack>
                        )}
                    </Stack>
                );
            },
        },
        {
            key: 'column8',
            name: '',
            fieldName: 'moreOptions',
            minWidth: 50,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            isResizable: true,
            isCollapsible: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item) => {
                const count = item?.owners?.length;
                return (
                    <Stack className="column-clickable-cell">
                        <ProjectOptionsMenu project={item} handleEdit={handleEdit} handleDelete={handleDelete} />
                    </Stack>
                );
            },
        }
    ];

    const columnsTeam: IColumn[] = [

        {
            key: 'column1',
            name: 'Project Name',
            fieldName: 'title',
            minWidth: 400,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: IProjectListResponse) => {
                return (
                    <Stack>
                        <Stack
                            onClick={(e) => openProjectURLUrl(e, item.number)}
                            className="column-clickable-cell"
                        ><TooltipHost content={item.title}>
                                {item.title?.trim() ? (
                                    truncateText(item.title.trim(), 55, 55)
                                ) : (
                                    item.number
                                )}
                            </TooltipHost>
                        </Stack>
                    </Stack>
                );
            },
        },

        {
            key: 'column2',
            name: 'Description',
            fieldName: 'description',
            minWidth: 400,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    marginLeft: 75,
                    lineHeight: 16
                },
            },
            //flexGrow: 1,
            isResizable: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: IProjectListResponse) => {
                return (
                    <>
                        <Stack className="column-clickable-cell" >
                            <Stack horizontal>
                                <View item={item} />
                                {item?.title?.toLowerCase() !== 'project not available' && item?.spoUrl && (<Icon
                                    onClick={() => opeSPOUrl(item.spoUrl)}
                                    data-testid="copy-icon"
                                    iconName="Link"
                                    style={clipBoardIconStyle}
                                    aria-label="click on this icon to copy the spo url to clipboard"
                                />)}
                            </Stack>
                        </Stack>
                        <Stack className="column-cell" style={{ marginLeft: 75, marginTop: -20 }}>
                            <TooltipHost content={item.description}>
                                {truncateText(item.description?.trim(), 55, 55)}
                            </TooltipHost>
                        </Stack>
                    </>
                );
            },
        }

    ];

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    _selection = new Selection({
        onSelectionChanged: () => {
            let newResult = '';
            setShowAccessError(false);
            setOwner(true);
            (_selection.getSelection() as IProjectListResponse[]).forEach((item, index) => {
                if (!item.owners.map(owner => owner.toLowerCase()).includes(loginUserpersonaProps?.secondaryText.toLowerCase())) {
                    setOwner(false);
                }

                newResult += item.number;
                if (index < _selection.getSelection().length - 1) {
                    newResult += ',';
                }
            });
            setSelectedList(newResult);
            setselectedCount(_selection.getSelectedCount());

        },
    });

    function openProjectURLUrl(e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string): void {
        history.push(`/viewproject?componentName=view&projectNumber=${projectNumber}&backToProjectList=true`);
    }

    function openEditProject(e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, projectNumber: string): void {
        history.push(`/viewproject?componentName=view&projectNumber=${projectNumber}&backToProjectList=true`);

    }

    function getKey(item: any, index?: number): string {
        return item.key;
    }

    function clearVariables() {
        setOwner(true);
        setSelectedList("");
        setShowAccessError(false);
    }



    const [isDialogOpen, setIsDialogOpen] = React.useState(false);


    const filterTextRef = React.useRef(null);

    const handleFilterClick = () => {

        setIsDialogOpen(!isDialogOpen);
    };

    const handleFilterDismiss = () => {
        setIsDialogOpen(false);
    };

    const loadMore = () => {
        setCurrentPage(prevPage => prevPage + 1);
        if (selectedRecentFilter === 'recent-my-projects-btn')
            dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, projectSearchRequest, ProjectListType.DEFAULT, ProjectListFilter.ALL, currentPage + 1, recordsPerPage));
        else
            dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, projectSearchRequest, ProjectListType.DEFAULT, ProjectListFilter.TEAM, currentPage + 1, recordsPerPage));

    };

    return (
        <>
            {showAccessError && selectedCount > 0 && (
                <>

                    <DisplayMessage
                        accessabilityMessage={"Please uncheck the projects that you do not own to proceed with the deletion"}
                        messageType={MessageBarType.error}
                        message={"Please uncheck the projects that you do not own to proceed with the deletion"}
                    />
                </>
            )}
           

            <Stack>
                {!isProjectGettingCreated && projectCreationSuccess && (
                    <>
                        <DisplayMessage
                            accessabilityMessage={projectCreationResponse.projectTitle + " has been created."}
                            messageType={MessageBarType.success}
                            message={`"${projectCreationResponse.projectTitle}"` + " has been created."}
                        />
                    </>
                )}
                {fetchingProjectToEdit && (
                    <>
                        <span role="alert" aria-label={GenericMessages.fetchingProjectToEdit} aria-live="assertive" />
                        <div style={spinnerCss}>
                            <DisplaySpinner
                                accessabilityMessage={GenericMessages.fetchingProjectToEdit}
                                spinnerText={GenericMessages.fetchingProjectToEdit}
                                spinnerPosition={"right"}
                            />
                        </div>
                    </>
                )}
                {!isProjectListLoading && isProjectListLoadingError && (
                    <>
                        <ErrorDisplay source={projectListError?.errorMessage} />

                    </>
                )}
                {!isProjectDeleting && projectDeleteSuccess && (
                    <>
                        <DisplayMessage
                            accessabilityMessage={ProjectEventNames.singleProjectDeleteSuccess}
                            messageType={MessageBarType.success}
                            message={ProjectEventNames.singleProjectDeleteSuccess}
                        />
                    </>
                )}
                {!loadingLoginUserProfile && !isloadingLoginUserProfileError && 
                    !fetchingProjectToEdit && (
                        <>
                            <Stack horizontal className="top-panel">
                                <Stack horizontal className="recent-container" tokens={{ childrenGap: 28 }}>
                                    <span className="recent-text">Recent</span>
                                    <Stack horizontal tokens={{ childrenGap: 14 }} >

                                        <button
                                            id="recent-my-projects-btn"
                                            onClick={(e) => {
                                                if (selectedRecentFilter !== 'recent-my-projects-btn') {
                                                    setCurrentPage(1);
                                                    setDetailListColumns(columns);
                                                    updateSelectedRecentFilter('recent-my-projects-btn')
                                                    var searchFilter = { ...projectSearchRequest, owner: loginUserpersonaProps?.secondaryText, showStatusCountList: false };
                                                    dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, searchFilter, getProjectListType(), ProjectListFilter.ALL, currentPage, recordsPerPage));
                                                }
                                            }}
                                            className={selectedRecentFilter === "recent-my-projects-btn" ? "recent-button-selected my-projects-button" : "recent-button my-projects-button"}
                                        >
                                            <div className={selectedRecentFilter === "recent-my-projects-btn" ? "recent-button-text-selected" : "recent-button-text"}>My projects</div>
                                        </button>

                                        <button
                                            id="recent-teams-projects-btn"
                                            onClick={(e) => {
                                                if (selectedRecentFilter !== 'recent-teams-projects-btn') {
                                                    setCurrentPage(1);
                                                    setDetailListColumns(columnsTeam);
                                                    updateSelectedRecentFilter('recent-teams-projects-btn');
                                                    var searchFilter = { ...projectSearchRequest, owner: loginUserpersonaProps?.secondaryText, showStatusCountList: false };
                                                    dispatch(requestProjectListDetailV2(loginUserpersonaProps?.optionalText, searchFilter, getProjectListType(), ProjectListFilter.TEAM, currentPage, recordsPerPage));
                                                }
                                            }}
                                            className={selectedRecentFilter === "recent-teams-projects-btn" ? "recent-button-selected my-teams-projects-button" : "recent-button my-teams-projects-button"}
                                        >
                                            <div className={selectedRecentFilter === "recent-teams-projects-btn" ? "recent-button-text-selected" : "recent-button-text"}>My team projects</div>
                                        </button>
                                        {projectListFilterDetails?.length > 0 && (
                                        <div className="loaded-records-count">
                                          Showing {projectListDetails.items?.length}/{projectListDetails.totalRecords} projects
                                        </div>
                                        )}
                                    </Stack>
                                </Stack>
                                <Stack horizontal>
                                    <Stack style={{ position: "fixed", right: 20 }}>
                                        <SearchBox
                                            placeholder="Find"
                                            underlined
                                            onChange={loadFilteredData}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack>
                                {(isDeleteGridLoading) && (
                                    <>
                                        <span role="alert" aria-label={GenericMessages.deletingProject} aria-live="assertive" />

                                        <div style={spinnerDeleteCss}>
                                            <DisplaySpinner
                                                accessabilityMessage={GenericMessages.deletingProject}
                                                spinnerText={GenericMessages.deletingProject}
                                                spinnerPosition={"right"}
                                            />
                                        </div>

                                    </>
                                )}
                              

                                {!isProjectListLoading && isProjectListLoadingError && (
                                    <>
                                        <ErrorDisplay source={projectListError?.errorMessage} />
                                    </>
                                )}
                                {!isProjectListLoadingError && (
                                    <>

                                        {projectListFilterDetails?.length === 0 && (!isProjectListLoading) && (<div style={displayMessage}>Sorry, there are no projects to display at the moment</div>)}
                                        <div style={divGridCSS}>

                                            {projectListFilterDetails?.length > 0 && (
                                                <DetailsList
                                                    items={projectListFilterDetails}
                                                    columns={detailListColumns}
                                                    selectionMode={SelectionMode.none}
                                                    getKey={getKey}
                                                    setKey="multiple"
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    isHeaderVisible={true}
                                                    selection={_selection}
                                                    enterModalSelectionOnTouch={true}
                                                    onShouldVirtualize={() => false}
                                                    styles={{
                                                        root: {
                                                            selectors: {
                                                                '.ms-DetailsRow': {
                                                                    borderBottom: '1px solid #D1D1D1', // Adjust border style as needed
                                                                    //borderLeft:'1px solid #D1D1D1',
                                                                    height: 48,
                                                                    fontSize: 14,
                                                                    color: '#242424',
                                                                    width: '100%'
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}

                                            <div className="load-more-container">
                                                {isProjectListLoading ? (
                                                    <div className="spinner-container">
                                                        <Spinner size={SpinnerSize.medium} />
                                                        <span className="spinner-text">{spinnerText}</span>
                                                    </div>
                                                ) : (
                                                    projectListDetails.items?.length < projectListDetails.totalRecords && (
                                                        <button className="load-more-button" onClick={loadMore}>
                                                            Load More
                                                        </button>
                                                    )
                                                )}
                                            </div>

                                        </div>


                                    </>
                                )}
                            </Stack>
                        </>
                    )}
            </Stack>
        </>
    )



}
export default ProjectsListPage;

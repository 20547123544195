import * as React from "react";
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../../Shared/Reducers/Project.reducer";
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Reducer } from "redux";
import { ILitigationCase, ILitigationCaseFiles } from "../../../../../Models/ILitigationCase";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { ILitigationFilesRequest } from "../../../../../Models/ILitigationCaseRequest";
import { ILitigationClassifyFileRequest } from "../../../../../Models/ILitigationClassifyFileRequest";
import { classifyFilesRequest, filesClassifySummaryByUserRequest } from "../../../../../Shared/Actions/Project.action";
import { Icon, IContextualMenuProps, Stack } from "@fluentui/react";
import ClassificationSettingsModal from "./FilesClassification/ClassificationSettingsModal";
import { IRequestFilesClassifySummaryByUser } from "../../../../../Models/IRequestFilesClassifySummaryByUser";

export type IClassifyFilesProps = {
    gridFiles?: ILitigationCaseFiles[];
    files?: ILitigationCaseFiles[];
    cases?: ILitigationCase[];
    refreshCaseDetailsView(files): void
}
const ClassifyFiles = (classifyFilesProps: IClassifyFilesProps) => {
    const onClassifySettingsHandler = (event: React.MouseEvent<HTMLElement>, item?: any) => {
        setShowClassifySettingsModal(true)
    };

    const menuProps: IContextualMenuProps = {
        items: [
            // {
            //     key: 'classifySettings',
            //     text: 'Classify Settings',
            //     iconProps: { iconName: 'Settings' },
            //     onClick: onClassifySettingsHandler,
            // },
        ],
    };
    const { files, cases, refreshCaseDetailsView, gridFiles } = classifyFilesProps
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;
    const [showClassifySettingsModal, setShowClassifySettingsModal] = React.useState<boolean>(false);
    const [shouldDisable, setShouldDisable] = React.useState<boolean>(true);

    const { loginUserpersonaProps, isFilesClassificationLoading,
        projectProps, litigationClassifyFileResponse, responseErrorFilesClassifySummaryByUser, isLoadingFileSummaryByUser,
        responseFilesClassifySummaryByUser
    } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const classifyFilesHandler = () => {
        let caseFileRequest: ILitigationFilesRequest[] = [];
        cases?.map((caseItem: ILitigationCase) => {
            if (files.some(file => file?.caseId === caseItem?.caseId)) {
                const detail: ILitigationFilesRequest = {
                    litigationCase: caseItem as ILitigationCase,
                    files: files.filter((fileItem) => fileItem.caseId === caseItem.caseId) as ILitigationCaseFiles[],
                };
                caseFileRequest.push(detail);
            }
            else {
                const detail: ILitigationFilesRequest = {
                    litigationCase: caseItem as ILitigationCase,
                    files: [],
                };
                caseFileRequest.push(detail);
            }
        });
        const litigationClassifyFileRequest: ILitigationClassifyFileRequest = {
            userAlias: loginUserpersonaProps?.secondaryText,
            caseFileDetails: caseFileRequest,
            projectDescription: projectProps?.description,
            projectNumber: projectProps?.number
        };
        dispatch(classifyFilesRequest(litigationClassifyFileRequest));
    }

    React.useEffect(() => {
        if (litigationClassifyFileResponse?.status === 200) {
            if (!litigationClassifyFileResponse?.isProcessingByWebjob) {
                files.map((file: ILitigationCaseFiles) => {
                    var filterFileFromResponse = litigationClassifyFileResponse?.openAICaseFileClassificationList?.filter(obj => obj.caseFile.id === file.id)
                    const fileClassificationResponse = filterFileFromResponse[0].openAICaseFileClassificationResponse
                    const isFileClassificationSuccess = filterFileFromResponse[0]?.isSuccess
                    if (isFileClassificationSuccess) {
                        file.justification = fileClassificationResponse?.justification
                        file.decision = fileClassificationResponse.decision?.toString();
                        file.fileRelevanceScore = fileClassificationResponse?.fileRelevanceScore?.toString();
                        file.documentTheme = fileClassificationResponse?.documentTheme?.toString();
                        file.documentSummary = fileClassificationResponse?.documentSummary?.toString();
                    }

                });
                setShouldDisable(false);

            }
            else {
                setShouldDisable(true)
            }


            if (gridFiles && files) {
                // Iterate over each file in files array
                files.forEach(fileToUpdate => {
                    // Find the index of the file in gridFiles with the matching id
                    const index = gridFiles.findIndex(gridFile => gridFile.id === fileToUpdate.id);

                    // If a match is found, update the gridFiles object at that index
                    if (index !== -1) {
                        gridFiles[index] = { ...gridFiles[index], ...fileToUpdate };
                    }
                });
            }
            refreshCaseDetailsView(gridFiles);
        }
    }, [litigationClassifyFileResponse])
    const handleClassificationSettingsModalCloseDialog = () => {
        setShowClassifySettingsModal(false)
    }

    React.useEffect(() => {
        if (cases && Object.keys(cases).length > 0) {
            const litigationCase: ILitigationCase = cases?.find((caseNameValue: ILitigationCase) => caseNameValue.isSelected === true);
            var requestFilesClassifySummaryByUser: IRequestFilesClassifySummaryByUser = {
                userAlias: loginUserpersonaProps?.secondaryText,
                projectNumber: projectProps?.number,
                caseName: litigationCase?.caseName
            }
            dispatch(filesClassifySummaryByUserRequest(requestFilesClassifySummaryByUser));
        }
    }, [cases])

    React.useEffect(() => {
        setShouldDisable(true)
    }, [])

    React.useEffect(() => {
        if (Object.keys(responseFilesClassifySummaryByUser).length > 0) {
            if (responseFilesClassifySummaryByUser?.status === 'InProgress' || responseFilesClassifySummaryByUser?.status === 'NotStarted') {
                setShouldDisable(true)
            } else {
                setShouldDisable(false)
            }
        }
        else {
            setShouldDisable(false)
        }
    }, [responseFilesClassifySummaryByUser])

    return (<>

        {showClassifySettingsModal && (
            <ClassificationSettingsModal
                isOpen={showClassifySettingsModal}
                title='Files classification settings'
                onDismiss={handleClassificationSettingsModalCloseDialog}
                dialogWidth="670px"
                dialogHeight="300px"
                files={files}
                cases={cases}
            />
        )}
        <div style={{ backgroundColor: '' }}>
            {/* <DefaultButton
                    id="classify-btn"
                    text="Classify"
                    splitButtonAriaLabel="Classify files options"
                    aria-roledescription="Classify files options"
                    onClick={classifyFilesHandler}
                    style={{ border: '1px solid' }}
                    disabled={(isFilesClassificationLoading || shouldDisable) ? true : false}
                /> */}
            <button
                disabled={(isFilesClassificationLoading || shouldDisable || isLoadingFileSummaryByUser) ? true : false}
                id="assign-tags-btn"
                onClick={classifyFilesHandler}
                style={{ marginRight: '5', height: 32, minWidth: 100 }}
            >
                <div style={{ fontWeight: "600" }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <>
                            <Icon iconName={'AllApps'} style={{ marginRight: '5px', marginTop: 2 }} />
                            <span>Classify</span>
                        </>
                    </Stack>
                </div>
            </button>
        </div>

    </>);
}

export default ClassifyFiles 
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import * as React from "react";
import { Reducer } from "redux";
import { Icon, Stack } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import "../Styles/DashboardPageStyles.css";
import { IProjectAppState } from "src/Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../Shared/Sagas/Project.sagas";
import MyProject from "./MyProjects/MyProject";
import { projectTitle, seeProjects } from "./MyProjects/Styles/MyProject";
import MyHistory from "./History/MyHistory";
import MyBookmark from "./Bookmark/MyBookmark";
import { seeHistoryText } from "./History/Styles/History";
import Banner from "../../Banner/Banner";
import RecentDocuments from "./RecentDocuments/RecentDocuments";
import { minWidth } from "src/Components/Shared/Styles/Media";
export function DashboardPage(): React.ReactElement {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { useSelector, dispatch } = reduxContext;

  const [modalVisibility, setModalVisibility] = React.useState(false);

  const {
    loginUserpersonaProps, isLoadingBannerInformation
  } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);
  const [greeting, setGreeting] = React.useState("");
  React.useEffect(() => {
    const currentTime = new Date();
    const hours = currentTime.getHours();


    if (hours >= 0 && hours < 12) {
      setGreeting('Good morning');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon');
    } else {
      setGreeting('Good evening');
    }
  }, []);

  let history = useHistory();
  const closeModal = () => {
    setModalVisibility(false);
  }
  function openProjectList(statusType: string): void {
    if (statusType === 'history')
      history.push("/history");
    else
      history.push("/bookmarks");

  }

  return (
    <>
      <Stack className="dashboard-container" tokens={{ childrenGap: 20 }}>
        <Stack horizontal>
          <p className="greeting-text">{greeting}, {loginUserpersonaProps?.text}</p>
        </Stack>
        {!isLoadingBannerInformation && <Banner />}
        <Stack className="dashboard-suggested-files" tokens={{ childrenGap: 20 }}>

          <Stack horizontal>
            <Stack>
              <Icon
                iconName="FabricFolder"
                style={{
                  fill: '#000',
                  width: '13.333px',
                  height: '12px',
                  marginTop: 3
                }}
              />
            </Stack>
            <Stack horizontal style={{width:'85%', minWidth:300}}>
              <Stack style={projectTitle}>
                Recent documents
              </Stack>
              <Stack style={{ marginTop: 4 }}>
                <Icon iconName="Info" title="Related files to user's projects will be shown here for reference" />
              </Stack>
            </Stack>
            
          </Stack>

          <Stack>
            <div style={{ textAlign: 'center' }}>
              <RecentDocuments/>
            </div>
          </Stack>

        </Stack>

        <Stack horizontal wrap tokens={{ childrenGap: 20 }} style={{minWidth:375}}>


          <Stack style={{
            width: '363px',
            height: '290px',
            flexShrink: 0,
            borderRadius: '4px',
            background: '#FFF',
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
            padding: '16px'
          }}>
            <MyProject></MyProject>
          </Stack>

          <Stack style={{
            width: '363px',
            height: '290px',
            flexShrink: 0,
            borderRadius: '4px',
            background: '#FFF',
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
            padding: '16px'
          }}>
            <Stack style={{ height: 185 }}>
              <MyHistory></MyHistory></Stack>
            <Stack style={seeProjects}>
              <div style={seeHistoryText} tabIndex={0}>
                <Stack onClick={(e) => openProjectList("history")} >See all history</Stack>
              </div>
            </Stack>
          </Stack>

          <Stack style={{
            width: '363px',
            height: '290px',
            flexShrink: 0,
            borderRadius: '4px',
            background: '#FFF',
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
            padding: '16px 16px 5px 16px'
          }}>
            <Stack style={{ height: 185 }}>
              <Stack>
                <MyBookmark></MyBookmark>
              </Stack>
            </Stack>
            <Stack style={seeProjects}>
              <div style={seeHistoryText} tabIndex={0}>
                <Stack onClick={(e) => openProjectList("bookmark")} >See all bookmarks</Stack>
              </div>
            </Stack>
          </Stack>


        </Stack>
      </Stack>
    </>
  )
}
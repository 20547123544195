import { IPersonaProps } from "@fluentui/react";
import {
    IHttpClient,
    IHttpClientRequest,
    IHttpClientResult,
    IHttpHeader
} from "@micro-frontend-react/employee-experience/lib/IHttpClient";
import axios, { AxiosError } from "axios";
import { IShowBanner } from "../Models/Banner/IShowBanner";
import {
    IRequestAddMembersToProject,
    IRequestBannerInformation,
    IRequestDeleteFile,
    IRequestFeatureFlagInformation,
    IRequestGetSimilarProject,
    IRequestMemberStatusUpdate,
    IRequestProjectCreate,
    IRequestProjectDueDate,
    IRequestProjectEdit,
    IRequestProjectListDetails,
    IRequestProjectListDetailsV2,
    IRequestProjectSearch,
    IRequestProjectStatusUpdate,
    IRequestUploadFilesToProject,
    IRequestUserHistory,
    IRequestUserNotification,
    IResponseUserNotification,
    IUpdateUserNotification,
    IRequestBookmark,
    IRequestAddProjectToUserHistory,
    IRequestProjectFileTagsUpdate,
    IRequestRecentDocumentsList,
    IRequestLawFirmSearch,
    IRequestLitiagtionCases,
    IRequestLitiagtionCaseData,
    IRequestLitiagtionCaseFiles,
    IRequestLitiagtionFileContent,
    IRequestDownloadLitiagtionCaseFiles,
    IRequestLitiagtionFileURL,
    IRequestLitigationFeatureFlag,
    IRequestIsUserPartofLitigationSgGroup,
    IRequestLitigationUserQuery,
    IRequestLitigationSaveUserQuery,
    IRequestLitigationUpdateUserQuery,
    IRequestLitigationSaveUserTags,
    IRequestLitigationDeleteQuery,
    IClassifyFilesRequest,
    IFilesClassifySummaryByUserRequest,
    IRequestFileHistory,
} from "../Shared/Actions/Project.action-types";
import { customAxiosApi, globalConfig } from "./HttpInterceptor";

import { getFormattedDate } from "../Helpers/ProjectHelper";
import { CelaLegalGroupNames } from "../Helpers/ProjectConstants";
import { ProjectListFilter } from "../Helpers/ProjectEnum";
import { IBookmarkRequest } from "src/Models/IBookmarkRequest";
import { memberNameStyle } from "src/Components/Projects/Styles/ViewProject";
import { ILawFirmAdaptiveDetails } from "src/Models/ILawFirmAdaptiveDetails";
import { ILitigationCase, ILitigationCaseDataResponses, ILitigationCaseFiles, ILitigationCaseFilesResponses, ILitigationCaseResponses, ILitigationFileContentResponses, ILitigationSaveUserTagsRequest, ILitigationSaveUserTagsResponses } from "src/Models/ILitigationCase";
import { ILitigationClassifyFileResponse } from "../Models/ILitigationClassifyFileResponse";
import { IResponseFilesClassifySummaryByUser } from "../Models/IResponseFilesClassifySummaryByUser";
import { getProjectStateBusinessProcessProperties } from "src/Helpers/ProjectTelemtry";
import { IFileHistoryResponse } from "src/Models/FileHistory/IFileHistoryResponse";

export const ProjectAPI = {
    //This function will call CreateProject api which will upload files into SPO and creates a record in cosmos graph
    async createProject(action: IRequestProjectCreate, httpClient: IHttpClient, correlationId: string) {
        try {
            const formData = new FormData();
            const accessToken = await getLMIToken();
            let lastModifiedBy: string = action?.projectCreate?.userMailbox;
            action.projectCreate.projectFiles?.projectFile?.map((projectFile, index) => {
                formData.append(`File`, projectFile.file);
            });
            formData.append("Name", action.projectCreate?.projectProps?.title);
            formData.append("Description", action.projectCreate?.projectProps?.description);
            formData.append("Status", action.projectCreate?.projectProps?.status);
            formData.append("StartDate", action.projectCreate?.projectProps?.startDate);
            formData.append("EndDate", action.projectCreate?.projectProps?.endDate);
            formData.append("workarea", action.projectCreate?.projectProps?.workArea);
            formData.append("caseIds", action.projectCreate?.projectProps?.caseIds);
            let lastIndex: number = 0;
            let isloginUserFound: boolean = false;

            let members = action.projectCreate.projectMembers?.membersPicked;
            let owners = action.projectCreate.projectOwners?.membersPicked;

            members = members?.filter(function (item) {
                for (var i = 0, len = owners.length; i < len; i++) {
                    if (owners[i].secondaryText === item.secondaryText) {
                        return false;
                    }
                }
                return true;
            });

            members?.map((member: IPersonaProps) => {
                formData.append(`members[${lastIndex}].alias`, member?.secondaryText?.toLowerCase());
                formData.append(`members[${lastIndex}].accessPolicy`, "Write");
                formData.append(`members[${lastIndex}].personalNumber`,
                    member?.optionalText === member?.secondaryText
                        ? member.optionalText.toLowerCase()
                        : member.optionalText
                );
                formData.append(`members[${lastIndex}].isResponsible`, "false");
                if (member.secondaryText === member?.optionalText) {
                    formData.append(`members[${lastIndex}].isUserNotInTenant`, "true");
                }
                lastIndex = lastIndex + 1;
            });
            //Add CELA group
            if (action.projectCreate.isCELAPeopleCheckedLocally) {
                formData.append(`members[${lastIndex}].alias`, CelaLegalGroupNames.celaLegalGroup);
                formData.append(`members[${lastIndex}].accessPolicy`, "Write");
                formData.append(`members[${lastIndex}].isResponsible`, "false");
                lastIndex = lastIndex + 1;
            }
            owners?.map((member: IPersonaProps) => {
                if (member.secondaryText === action.projectCreate.userMailbox) {
                    isloginUserFound = true;
                }
                formData.append(`members[${lastIndex}].alias`, member?.secondaryText?.toLowerCase());
                formData.append(`members[${lastIndex}].accessPolicy`, "Write");
                formData.append(`members[${lastIndex}].personalNumber`,
                    member?.optionalText === member?.secondaryText
                        ? member.optionalText.toLowerCase()
                        : member.optionalText
                );
                formData.append(`members[${lastIndex}].isResponsible`, "true");
                lastIndex = lastIndex + 1;
            });
            // add loggedin user as owner if owner list is empty and 
            if (!isloginUserFound && action.projectCreate?.projectOwners?.membersPicked?.length == 0) {
                const loginUserDetails = await ProjectAPI.getLoginUser(action.projectCreate.userMailbox, httpClient);
                let userprincipalName: string = "";
                if (loginUserDetails?.length > 0 && loginUserDetails[0]?.userPrincipalName?.toLowerCase()) {
                    userprincipalName = loginUserDetails[0]?.userPrincipalName;
                } else {
                    userprincipalName = action.projectCreate.userMailbox;
                }
                formData.append(`members[${lastIndex}].isResponsible`, "true");
                formData.append(`members[${lastIndex}].alias`, userprincipalName.toLowerCase());
                formData.append(`members[${lastIndex}].accessPolicy`, "Write");
                lastIndex = lastIndex + 1;
            }
            action.projectCreate.projectTags?.tags?.map((projectTag: string, index: number) => {
                formData.append(`Tags[${index}]`, projectTag);
            });
            let i: number = 0;
            if (action.projectCreate?.projectFileTags) {
                action.projectCreate.projectFileTags?.map((item, index) => {
                    // var newTagNames =  encodeURIComponent(action.projectCreate.projectFileTags.tags);
                    let filename = item.fileName;
                    let fileTags = item.tags[0];
                    //let fileTags = item.
                    formData.append(`FileTags[${i}]`, `${item.fileName}:${item.tags}`);
                    i = i + 1;
                });
            }
            formData.append("lastModifiedBy", lastModifiedBy?.toLowerCase());
            globalConfig.headers.Authorization = `Bearer ${accessToken}`
            globalConfig.headers['x-correlation-id'] = correlationId
            const url = `/projects`;
            const response = await customAxiosApi.post(url, formData, globalConfig);
            return response;
        } catch (error: any) {
            throw error;
        }
    },

    //This function will search for the projects (Billable and NonBillable)
    async projectStatusCounts(action: IRequestProjectSearch, httpClient: IHttpClient) {
        try {
            const accessToken = await getLMIToken();
            let lmiApi = '';
            if (action.startDate) {
                lmiApi = `${__API_BASE_LMI_URL__}/Projects/StatusCount?personalNumber=${action.personal_number?.toLowerCase()}&createdDate=${action.startDate}`;
            }
            else 
            {
                lmiApi = `${__API_BASE_LMI_URL__}/Projects/StatusCount?personalNumber=${action.personal_number?.toLowerCase()}`;

            }
            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    },

    async dueDateProject(action: IRequestProjectDueDate, httpClient: IHttpClient) {
        try {
            const accessToken = await getLMIToken();
            const lmiApi = `${__API_BASE_LMI_URL__}/Projects?personalNumber=${action.personal_number.toLowerCase()}&endDate=${action.endDate}&CurrentPage=1&RecordsPerPage=5&status=Open`;
            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    },

    //This function will get the users from the graph based on the parameter searchUserText
    async getFilteredUsers(searchUserText: string, httpClient: IHttpClient, limitUsersFromGraph: number, graphToken?: string) {
        const graphAccessToken = graphToken ?? await getGraphToken();
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${graphAccessToken}`,
            },
        };
        const url = `${__GRAPH_BASE_URL__}users?$filter=((startswith(displayName%2C+'${searchUserText}') or startswith(userprincipalName%2C+'${searchUserText}') or startswith(mail%2C+'${searchUserText}') )and accountEnabled eq true)&$top=${limitUsersFromGraph}&$select=onPremisesImmutableId,displayName,userPrincipalName,jobTitle,mail,externalUserState, userType`;
        const response = await axios.get(url, config);
        const groupEntityUrl = `${__GRAPH_BASE_URL__}groups?$filter=(startswith(displayName%2C+'${searchUserText}') or startswith(mail%2C+'${searchUserText}')) and securityEnabled eq true&$top=${limitUsersFromGraph}&$select=displayName,mail,jobTitle`;
        const groupResponse = await axios.get(groupEntityUrl, config);
        const mergedUsers = [].concat(response?.data?.value, groupResponse?.data?.value);
        return mergedUsers;
    },

    //This function will fetch user photo for the current passed in email address
    async getUserPhoto(userEmail: string, httpClient: IHttpClient, graphToken?: string) {
        try {
            const graphAccessToken = graphToken ?? await getGraphToken();
            const response: any = await httpClient.request({
                url: `${__GRAPH_BASE_URL__}users/${userEmail}/photos/48x48/$value`,
                resource: `${__GRAPH_RESOURCE_URL__}`,
                responseType: "blob",
                accessToken: `${graphAccessToken}`,
            });
            return response.data;

        } catch (error: any | AxiosError) {
            return "No image found";
        }
    },



    async getUniqueFilterValues(action: ILitigationCase[], filterName: string, userAlias: string, httpClient: IHttpClient) {
        try {
            
            const accessToken = await getLMIToken();
            if (userAlias == undefined) {
                userAlias = "";
            }

            const singleCase = action.length > 0 ? action[0] : null;

            const data = singleCase ? singleCase : {};

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}/litigation/cases/uniquevalues`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": "",
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/cases/uniquevalues?filterName=${filterName}&userAlias=${userAlias?.toLowerCase()}`, request);


           
            return response.data as any;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    // This function returns the details of the user based on email passed
    async getLoginUser(userEmail: string, httpClient: IHttpClient) {
        const graphAccessToken = await getGraphToken();
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${graphAccessToken}`,
            },
        };
        const url = `${__GRAPH_BASE_URL__}users?$filter=(mail eq '${userEmail}' or userPrincipalName eq '${userEmail}' or otherMails/any(c:c eq '${userEmail}'))&$select=onPremisesImmutableId,displayName,userPrincipalName,jobTitle,id,mail,userType`;
        const response = await axios.get(url, config);
        return response.data.value;
    },


    async getProjectWorkArea(
        httpClient: IHttpClient,
        correlationId: string,
        lmiToken?: string
    ) {
        try {
            const accessToken = lmiToken ?? (await getLMIToken());
            const url: string = `/WorkAreas?skip=0&take=1000`;
            globalConfig.headers.Authorization = `Bearer ${accessToken}`;
            globalConfig.headers["x-correlation-id"] = correlationId;
            const response = await customAxiosApi.get(url, globalConfig);
            return response;
        } catch (error: any | AxiosError) {
            throw error;
        }
    },

    // Project File Tags Update in Edit Scenario
    async requestProjectFileTagsUpdateinView(
        action: IRequestProjectFileTagsUpdate,
        httpClient: IHttpClient,
        correlationId: string
    ) {
        try {
            const accessToken = await getLMIToken();
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };

            await httpClient.put(
                `${__API_BASE_LMI_URL__}/Projects/${action.projectContext.number}/File/Metadata?fileName=${action.projectFileTags.fileName}&fileTags=${action.projectFileTags.tags}`,
                request
            );

        } catch (error: any) {
            throw error;
        }
    },

    //Function to fetch project details for the project number 
    async getProjectDetailsById(projectNumber: string, userAlias: string, httpClient: IHttpClient, correlationId: string, lmiToken?: string) {
        try {
            const userAliasload = userAlias;
            const accessToken = lmiToken ?? (await getLMIToken());
            const response: any = await httpClient.request({
                url: `${__API_BASE_LMI_URL__}/Projects/${projectNumber}?userAlias=${userAliasload.toLowerCase()}&skip=0&take=50`,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: `${accessToken}`,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response;
        } catch (error: any | AxiosError) {
            throw error;
        }
    },

    async getFileTagsByIdAndFileName(fileName: string, projectNumber: string, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            fileName = encodeURIComponent(fileName);
            const response: any = await httpClient.request({
                url: `${__API_BASE_LMI_URL__}/Projects/Files?id=${projectNumber}&filename=${fileName}`,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: `${accessToken}`,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response.data;
        } catch (error: any | AxiosError) {
            throw error;
        }
    },

    //Function to delete file from sharepoint
    async deleteFile(action: IRequestDeleteFile, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const fileName = encodeURIComponent(action.fileName);
            const response = await httpClient.delete(
                `${__API_BASE_LMI_URL__}/Projects/${action.projectContext.number}/Documents?fileName=${fileName}`,
                request
            );
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },

    //Function to delete member from sharepoint
    async deleteMember(action: any, httpClient: IHttpClient, correlationId: string) {
        try {
            const formData = new FormData();
            const accessToken = await getLMIToken();
            formData.append("Name", action.projectProps?.title);
            formData.append("lastModifiedBy", action.lastModifiedBy);
            formData.append(`members[0].alias`, action.member?.secondaryText.toLowerCase());
            formData.append(`members[0].accessPolicy`, "3");
            formData.append(`members[0].personalNumber`, action.member?.optionalText.toLowerCase());
            // formData.append(`members[0].isResponsible`, action.isResponsible.toString());
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                    "x-correlation-id": correlationId,
                },
            };
            const url = `${__API_BASE_LMI_URL__}/projects/${action.projectContext.number}`;
            await axios.put(url, formData, config);
        } catch (error: any) {
            throw error;
        }
    },

    //Function to get list of projects
    async getProjectList(action: IRequestProjectListDetails, httpClient: IHttpClient) {
        try {
            const accessToken = await getLMIToken();
            if (action.projectSearchRequest.startDate == undefined) {
                action.projectSearchRequest.startDate = "";
            }
            if (action.projectSearchRequest.endDate == undefined) {
                action.projectSearchRequest.endDate = "";
            }
            if (action.projectSearchRequest.status == undefined) {
                action.projectSearchRequest.status = "";
            }
            let lmiApi = `${__API_BASE_LMI_URL__}/Projects?personalNumber=${action.personal_number.toLowerCase()}&startDate=${action.projectSearchRequest?.startDate}&endDate=${action.projectSearchRequest?.endDate}&status=${action.projectSearchRequest?.status}`;
            if (action?.projectSearchRequest?.showStatusCountList) {
                lmiApi = `${__API_BASE_LMI_URL__}/Projects?personalNumber=${action.personal_number.toLowerCase()}&createdDate=${action.projectSearchRequest?.startDate}&status=${action.projectSearchRequest?.status}`
            }

            //const lmiApi = `${__API_BASE_LMI_URL__}/Projects?personalNumber=458912&CurrentPage=1&RecordsPerPage=50`;
            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
            });
            return response;
        } catch (error: any) {
            throw error;
        }
    },

    //Function to get list of projects
    async getProjectListV2(action: IRequestProjectListDetailsV2, httpClient: IHttpClient) {
        try {
            const accessToken = await getLMIToken();
            if (action.projectSearchRequest.startDate == undefined) {
                action.projectSearchRequest.startDate = "";
            }
            if (action.projectSearchRequest.endDate == undefined) {
                action.projectSearchRequest.endDate = "";
            }
            if (action.projectSearchRequest.status == undefined) {
                action.projectSearchRequest.status = "";
            }
            if (action.projectSearchRequest.owner == undefined) {
                action.projectSearchRequest.owner = "";
            }

            let lmiApi = `${__API_BASE_LMI_URL__}/Projects?personalNumber=${action.personal_number.toLowerCase()}&UserAlias=${action.projectSearchRequest.owner.toLowerCase()}&currentPage=${action.currentPage}&recordsPerPage=${action.recordsPerPage}`;
            if (action?.projectSearchRequest?.showStatusCountList) {
                lmiApi = `${__API_BASE_LMI_URL__}/Projects?personalNumber=${action.personal_number.toLowerCase()}&createdDate=${action.projectSearchRequest?.startDate}&status=${action.projectSearchRequest?.status}&UserAlias=${action.projectSearchRequest.owner.toLowerCase()}&currentPage=${action.currentPage}&recordsPerPage=${action.recordsPerPage}`
            }
            if (action?.filter === ProjectListFilter.TEAM) {
                lmiApi = `${__API_BASE_LMI_URL__}/Projects/MyTeamProjects?UserAlias=${action.projectSearchRequest.owner.toLowerCase()}&currentPage=${action.currentPage}&recordsPerPage=${action.recordsPerPage}`
            }
            // Uncomment below when My and MyTeamProjects endpoint ssupport sending all required details - https://microsoftit.visualstudio.com/OneITVSO/_workitems/edit/11590288
            /*
            if(action.filter === ProjectListFilter.MY) {
              lmiApi = `${__API_BASE_LMI_URL__}/Projects/My?userAlias=${action.projectSearchRequest?.owner}&personalNumber=${action.personal_number}&startDate=${action.projectSearchRequest?.startDate}&endDate=${action.projectSearchRequest?.endDate}&status=${action.projectSearchRequest?.status}`;
            }
      
            if(action.filter === ProjectListFilter.TEAM) {
              lmiApi = `${__API_BASE_LMI_URL__}/Projects/MyTeamProjects?userAlias=${action.projectSearchRequest?.owner}&personalNumber=${action.personal_number}&startDate=${action.projectSearchRequest?.startDate}&endDate=${action.projectSearchRequest?.endDate}&status=${action.projectSearchRequest?.status}`;
            }
            */
            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
            });
            return response;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    async getUserHistory(action: IRequestUserHistory, httpClient: IHttpClient, correlationId: string) {
        try {
            //TODO: pass correlation Id
            const accessToken = await getLMIToken();
            const url = `${__API_BASE_LMI_URL__}/Projects/History?userAlias=${action.historySearchRequest.userAlias.toLowerCase()}&currentPage=${action.historySearchRequest.currentPage}&recordsPerPage=${action.historySearchRequest.recordsPerpage}`;

            const response: any = await httpClient.request({
                url: url,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response;
        }
        catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    async getProjectHistoryList(action: IRequestUserHistory, httpClient: IHttpClient) {
        try {
            const accessToken = await getLMIToken();
            if (action.historySearchRequest.userAlias == undefined) {
                action.historySearchRequest.userAlias = "";
            }

            let lmiApi = `${__API_BASE_LMI_URL__}/Projects/History?userAlias=${action.historySearchRequest?.userAlias.toLowerCase()}&currentPage=0&recordsPerPage=1000`;

            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
            });
            return response?.data;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    //toggle project bookmark for user history page
    async toggleProjectBookmark(action: IBookmarkRequest, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const lmiApi = `${__API_BASE_LMI_URL__}/Projects/Bookmarks?userAlias=${action.userAlias.toLowerCase()}&projectNumber=${action.projectNumber}`;
            if (action.isBookmarked) {
                await httpClient.post(`${lmiApi}`, request);
            } else if (!action.isBookmarked) {
                await httpClient.delete(`${lmiApi}`, request);
            }
            return true;
        } catch (error: any) {
            throw error;
        }
    },

    async getProjectBookmarksList(action: IRequestBookmark, httpClient: IHttpClient) {
        try {
            const accessToken = await getLMIToken();
            if (action.bookmarkSearchRequest.userAlias == undefined) {
                action.bookmarkSearchRequest.userAlias = "";
            }

            let lmiApi = `${__API_BASE_LMI_URL__}/Projects/Bookmarks?userAlias=${action.bookmarkSearchRequest?.userAlias.toLowerCase()}&currentPage=0&recordsPerPage=1000&SortCategory=bookmarkeddate&SortOrder=decr`;

            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
            });
            return response?.data;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    //Function to update the member status
    async updateMemberStatus(action: IRequestMemberStatusUpdate, httpClient: IHttpClient, correlationId: string) {
        try {
            const formData = new FormData();
            const accessToken = await getLMIToken();
            formData.append("Name", action.projectProps?.title);
            formData.append(`members[0].alias`, action.member?.secondaryText.toLowerCase());
            formData.append(`members[0].accessPolicy`, "Write");
            formData.append(`members[0].personalNumber`, action.member?.optionalText.toLowerCase());
            formData.append(`members[0].isResponsible`, action.isResponsible.toString());
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                    "x-correlation-id": correlationId,
                },
            };
            const url = `${__API_BASE_LMI_URL__}/projects/${action.projectContext.number}`;
            await axios.put(url, formData, config);
        } catch (error: any) {
            throw error;
        }
    },

    //Function to update the member status
    async updateProjectStatus(action: IRequestProjectStatusUpdate, httpClient: IHttpClient, correlationId: string) {
        try {
            const formData = new FormData();
            const accessToken = await getLMIToken();
            formData.append("Name", action.projectProps?.title);
            formData.append("Description", action.projectProps?.description);
            formData.append("Status", action.newStatus);
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                    "x-correlation-id": correlationId,
                },
            };
            const url = `${__API_BASE_LMI_URL__}/projects/${action.projectContext.number}`;
            await axios.put(url, formData, config);
        } catch (error: any) {
            throw error;
        }
    },

    //Function to upload new files to existing project
    async uploadFileToExistingProject(action: IRequestUploadFilesToProject, httpClient: IHttpClient, correlationId: string) {
        try {
            const startDate = getFormattedDate(action.projectProps?.startDate)
            const endDate = getFormattedDate(action.projectProps?.endDate)
            const formData = new FormData();
            const accessToken = await getLMIToken();
            formData.append("Name", action.projectProps?.title);
            formData.append("Description", action.projectProps?.description);
            formData.append("Status", action.projectProps?.status);
            formData.append("StartDate", startDate ?? '');
            formData.append("EndDate", endDate ?? '');
            action.newFiles?.projectFile?.map((projectFile, index) => {
                if (projectFile.isNew === true) {
                    formData.append("file", projectFile.file);
                }
            });
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                    "x-correlation-id": correlationId,
                },
            };
            const url = `${__API_BASE_LMI_URL__}/projects/${action.projectContext.number}`;
            const response = await axios.put(url, formData, config);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },

    //Function to upload new files to existing project
    async addMemberToExistingProject(action: IRequestAddMembersToProject, httpClient: IHttpClient, correlationId: string) {
        try {
            const formData = new FormData();
            const accessToken = await getLMIToken();
            formData.append("Name", action.projectProps?.title);
            formData.append("Description", action.projectProps?.description);
            formData.append("lastModifiedBy", action.lastModifiedBy);
            let index: number = 0;
            action.newMember?.membersPicked?.map((member: IPersonaProps) => {
                if (member?.tertiaryText === 'true') {
                    formData.append(`members[${index}].alias`, member.secondaryText.toLowerCase());
                    formData.append(`members[${index}].accessPolicy`, "Write");
                    formData.append(`members[${index}].personalNumber`, member?.optionalText.toLowerCase());
                    formData.append(`members[${index}].isResponsible`, "false");
                    if (member.secondaryText === member?.optionalText) {
                        formData.append(`members[${index}].isUserNotInTenant`, "true");
                    }
                    index = index + 1;
                }
            });
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                    "x-correlation-id": correlationId,
                },
            };
            const url = `${__API_BASE_LMI_URL__}/projects/${action.projectContext.number}`;
            await axios.put(url, formData, config);
        } catch (error: any) {
            throw error;
        }
    },

    // Edit Project 
    async editProject(action: IRequestProjectEdit, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const formData = new FormData();
            let lastModifiedBy: string = action?.projectEdit?.userMailbox;
            // console.log("ProjectEdit Object", action.projectEdit);
            // console.log("Deleted Members", action.deletedProjectMembers);
            formData.append("Name", action.projectEdit?.projectProps?.title);
            formData.append("Description", action.projectEdit?.projectProps?.description);
            formData.append("Status", action.projectEdit?.projectProps?.status);
            formData.append("StartDate", action.projectEdit?.projectProps?.startDate);
            formData.append("EndDate", action.projectEdit?.projectProps?.endDate);
            formData.append("workArea", action.projectEdit?.projectProps?.workArea);
            formData.append("caseIds", action.projectEdit?.projectProps?.caseIds);
            action.projectEdit.projectFiles?.projectFile?.map((projectFile, index) => {
                if (projectFile.isNew === true) {
                    formData.append("file", projectFile.file);
                }
            });

            let members = action.projectEdit.projectMembers?.membersPicked;
            let owners = action.projectEdit.projectOwners?.membersPicked;

            members = members?.filter(function (item) {
                for (var i = 0, len = owners.length; i < len; i++) {
                    if (owners[i].secondaryText === item.secondaryText) {
                        return false;
                    }
                }
                return true;
            });

            let index: number = 0;
            members?.map((member: IPersonaProps) => {
                if (member?.tertiaryText === 'true') {
                    formData.append(`members[${index}].alias`, member.secondaryText.toLowerCase());
                    formData.append(`members[${index}].accessPolicy`, "Write");
                    formData.append(`members[${index}].personalNumber`, member?.optionalText.toLowerCase());
                    formData.append(`members[${index}].isResponsible`, "false");
                    if (member.secondaryText === member?.optionalText) {
                        formData.append(`members[${index}].isUserNotInTenant`, "true");
                    }
                    index = index + 1;
                }
            });
            //Add CELA group
            if (action.projectEdit.isCELAPeopleCheckedLocally) {
                formData.append(`members[${index}].alias`, CelaLegalGroupNames.celaLegalGroup);
                formData.append(`members[${index}].accessPolicy`, "Write");
                formData.append(`members[${index}].isResponsible`, "false");
                index = index + 1;
            }
            if (!action.projectEdit.isCELAPeopleCheckedLocally) {
                action.projectEdit?.projectMembers?.membersPicked?.map((member: IPersonaProps, index: number) => {
                    if (
                        member.secondaryText === CelaLegalGroupNames.celaLegalGroup ||
                        member.secondaryText === "lca@microsoft.com"
                    ) {
                        if (member.secondaryText.toLowerCase().indexOf("@") === -1) {
                            member.secondaryText = member.secondaryText + "@microsoft.com";
                        }
                        formData.append(`members[${index}].alias`, CelaLegalGroupNames.celaLegalGroup);
                        formData.append(`members[${index}].accessPolicy`, "3");
                        formData.append(`members[${index}].isResponsible`, "false");
                        index = index + 1;
                    }
                });
            }
            owners?.map((member: IPersonaProps) => {
                if (member?.tertiaryText === 'true') {
                    formData.append(`members[${index}].alias`, member.secondaryText.toLowerCase());
                    formData.append(`members[${index}].accessPolicy`, "Write");
                    formData.append(`members[${index}].personalNumber`, member?.optionalText.toLowerCase());
                    formData.append(`members[${index}].isResponsible`, "true");
                    index = index + 1;
                }
            });
            action.deletedProjectMembers?.membersPicked?.map((member: IPersonaProps) => {
                if (member?.tertiaryText === 'false') {
                    formData.append(`members[${index}].alias`, member.secondaryText.toLowerCase());
                    formData.append(`members[${index}].accessPolicy`, "3");
                    formData.append(`members[${index}].personalNumber`, member?.optionalText.toLowerCase());
                    formData.append(`members[${index}].isResponsible`, "false");
                    index = index + 1;
                }
            });
            if (action.projectEdit?.projectTags?.tags?.length > 0) {
                action.projectEdit?.projectTags?.tags.map((projectTag: string, index: number) => {
                    formData.append(`Tags[${index}]`, projectTag);
                });
            }
            else {
                formData.append(`Tags[0]`, "");
            }
            formData.append("lastModifiedBy", lastModifiedBy);
            globalConfig.headers.Authorization = `Bearer ${accessToken}`
            globalConfig.headers['x-correlation-id'] = correlationId
            const url = `/projects/${action.projectContext.number}`;
            await customAxiosApi.put(url, formData, globalConfig);
        } catch (error: any) {
            throw error;
        }
    },

    // Project File Tags Update in Edit Scenario
    async requestProjectFileTagsUpdate(action: IRequestProjectEdit, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            if (action.projectEdit?.projectFileTags && Array.isArray(action.projectEdit.projectFileTags)) {
                await Promise.all(action.projectEdit.projectFileTags.map(async (projectFileTags, index) => {
                    if (projectFileTags) {
                        var tagNames = projectFileTags.tags;
                        var fileName = projectFileTags.fileName;
                        await httpClient.put(
                            `${__API_BASE_LMI_URL__}/Projects/${action.projectContext.number}/File/Metadata?fileName=${fileName}&fileTags=${tagNames}`,
                            request
                        );
                    }
                }));
            }

        } catch (error: any) {
            throw error;
        }
    },

    // Project Insights - search for similar content results
    async searchSimilarProjects(action: IRequestGetSimilarProject, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const data = {
                searchText: action.projectProps?.title,
                currentPage: 1,
                PageSize: 10,
                ContentLength: 0,
                UserAlias: action?.userAlias.toLowerCase(),
            };
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/Projects/Search/`, request);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },

    // Function to delete Project 
    // Param - projectNumber (single project Number or comma separated string of multiple project number)
    // e.g.    projectNumber = "NB16762643072631"
    //         projectNumber = "NB16762643072631,NB1675467649311"
    async deleteProject(projectNumber: string, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.delete(
                `${__API_BASE_LMI_URL__}/Projects?Id=${projectNumber}`,
                request
            );
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },

    async requestBannerInformation(action: IRequestBannerInformation, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const lmiApi = `${__API_BASE_LMI_URL__}/User/Banner?userAlias=${action.loginUserAlias?.toLowerCase()}&appType=Teams`;
            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },

    async requestHideBannerForUser(action: IShowBanner, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            await httpClient.post(`${__API_BASE_LMI_URL__}/User/Banner?userAlias=${action.userAlias?.toLowerCase()}&showBanner=${action.showBanner}&appType=Teams`, request);
        } catch (error: any) {
            throw error;
        }
    },

    async requestFeatureFlag(action: IRequestFeatureFlagInformation, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const featureFlagURL = `${__FEATURE_FLAG_BASE_URL__}/FeatureFlags/ProjectFeature?userAlias=${action.loginUserAlias.toLowerCase()}`;
            const response: any = await httpClient.request({
                url: featureFlagURL,
                resource: `${__FEATURE_FLAG_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },

    async requestNotifications(action: IRequestUserNotification, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const notificationURL = `${__API_BASE_LMI_URL__}/User/Notifications?userAlias=${action.userNotificationRequest.userAlias?.toLowerCase()}&currentPage=${action.userNotificationRequest.currentPage}&recordsPerPage=${action.userNotificationRequest.recordsPerPage}&SortColumn=${action.userNotificationRequest.sortColumn}&SortOrder=${action.userNotificationRequest.sortOrder}`;
            const response: any = await httpClient.request({
                url: notificationURL,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },
    async updateNotifications(action: IUpdateUserNotification, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();

            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                    "x-correlation-id": correlationId,
                },
            };
            const data = {

            }
            const url = `${__API_BASE_LMI_URL__}/user/UpdateNotification?notificationId=${action?.userNotification?.notificationId}&isRead=${action?.userNotification?.isRead}`;
            await axios.put(url, data, config);
        } catch (error: any) {
            throw error;
        }
    },
    async addProjectToUserHistory(
        action: IRequestAddProjectToUserHistory,
        httpClient: IHttpClient,
        correlationId: string
    ) {
        try {
            const accessToken = await getLMIToken();
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const lmiApi = `${__API_BASE_LMI_URL__}/Projects/History?userAlias=${action.userAlias?.toLowerCase()}&projectNumber=${action.projectNumber}`;
            await httpClient.post(`${lmiApi}`, request);
            return true;
        } catch (error: any) {
            throw error;
        }
    },
    async getUserRecentDocuments(
        action: IRequestRecentDocumentsList,
        httpClient: IHttpClient,
        correlationId?: string
    ) {
        try {
            const accessToken = await getLMIToken();

            const lmiApi = `${__API_BASE_LMI_URL__}/User/RecentDocuments?userAlias=${action?.requestRecentDocuments?.userEmail?.toLowerCase()}&pageSize=${action?.requestRecentDocuments?.pageSize}`;

            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });

            return response?.data;
        } catch (error: any) {
            throw error;
        }
    },

    async getLawFirms(action: IRequestLawFirmSearch,
        httpClient: IHttpClient) {
        try {
            const accessToken = await getLMIToken();
            let name: string = '';
            let vendorNumber: string = '';
            if (isNaN(+action.searchCriteria.name)) {
                name = action.searchCriteria.name;
            }
            else {
                vendorNumber = action.searchCriteria.name;
            }

            const data = {
                name: action.searchCriteria.name,
                vendorNumber: action.searchCriteria.vendorNumber,
                includeAdaptiveCard: action.searchCriteria.includeAdaptiveCard,
                pagination: {
                    totalRecords: action.searchCriteria.pagination.totalRecords,
                    currentPage: action.searchCriteria.pagination.currentPage,
                    recordsPerPage: action.searchCriteria.pagination.recordsPerPage
                },
                filter: action.searchCriteria.filter
            }
            const url: string = `${__API_BASE_KNOWLEDGE_URL__}/searchlawfirms`;
            const header: IHttpHeader = {
                "Access-Control-Allow-Origin": "*"
            }
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_KNOWLEDGE_RESOURCE_ID__}`,
                accessToken,
                data
            }


            const lawFirmsJSON: any = await httpClient.post(url, request);
            const payload = {
                payload: lawFirmsJSON.data.payload,
            };

            const lawfirmDetailsJSON: ILawFirmAdaptiveDetails = {
                payload: payload,
                template: JSON.parse(lawFirmsJSON.data.adaptiveCard),
                totalRecords: lawFirmsJSON.data.totalRecords
            };
            return lawfirmDetailsJSON;

        } catch (error: any) {
            throw error;
        }
    },
    async getLitigationCases(action: IRequestLitiagtionCases, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            if (action.litigationCaseRequest.userAlias == undefined) {
                action.litigationCaseRequest.userAlias = "";
            }

            const data = {
                cases: action.litigationCaseRequest?.cases,
            };

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/cases?userAlias=${action.litigationCaseRequest?.userAlias.toLowerCase()}`, request);


            const litigationResponse: ILitigationCaseResponses = {};
            litigationResponse.litigationCaseResponses = response.data as any;
            return litigationResponse;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },
    async getLitigationCaseData(action: IRequestLitiagtionCaseData, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const data = {
                cases: action.litigationCaseDataRequest?.cases,
                UserAlias: action?.litigationCaseDataRequest.userAlias.toLowerCase(),
                viewType: action.litigationCaseDataRequest?.viewType,
            };

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/casedata?userAlias=${action.litigationCaseDataRequest?.userAlias.toLowerCase()}`, request);
            const litigationCaseDataResponse: ILitigationCaseDataResponses = {};
            litigationCaseDataResponse.litigationCaseDataResponses = response.data as any;
            return litigationCaseDataResponse;


        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    async getLitigationCasesFiles(action: IRequestLitiagtionCaseFiles, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const data = {
                cases: action.litigationCaseFilesRequest?.cases,
                UserAlias: action?.litigationCaseFilesRequest.userAlias.toLowerCase(),
                filter: action?.litigationCaseFilesRequest.filter,
                searchText: action?.litigationCaseFilesRequest.searchText,
                take: action?.litigationCaseFilesRequest.take,
                skip: action?.litigationCaseFilesRequest.skip,
                isKeywordSearch: action?.litigationCaseFilesRequest.isKeywordSearch,
            };

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/casefiles?userAlias=${action.litigationCaseFilesRequest?.userAlias.toLowerCase()}`, request);
            const litigationCaseFilesResponse: ILitigationCaseFilesResponses = {};
            const result = response.data as ILitigationCaseFilesResponses;;
            litigationCaseFilesResponse.totalRecords = result.totalRecords;
            litigationCaseFilesResponse.litigationCaseFiles = result.litigationCaseFiles;
            return litigationCaseFilesResponse;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    async downloadLitigationCasesFiles(action: IRequestDownloadLitiagtionCaseFiles, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const data = {
                cases: action.litigationCaseFilesRequest?.cases,
                UserAlias: action?.litigationCaseFilesRequest.userAlias.toLowerCase(),
                filter: action?.litigationCaseFilesRequest.filter,
                searchText: action?.litigationCaseFilesRequest.searchText,
                take: action?.litigationCaseFilesRequest.take,
                skip: action?.litigationCaseFilesRequest.skip,
                isKeywordSearch: action?.litigationCaseFilesRequest.isKeywordSearch,
            };

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
                responseType: 'text',
            };
            //const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/download?userAlias=${action.litigationCaseFilesRequest?.userAlias}`, request);
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/download?userAlias=${action.litigationCaseFilesRequest?.userAlias.toLowerCase()}`, request);
            if (response) {
                let blob: Blob | null = null;
                if (response.data instanceof ArrayBuffer) {
                    // If the data is an ArrayBuffer
                    blob = new Blob([response.data], { type: 'text/csv' });
                } else if (typeof response.data === 'string') {
                    // If the data is a string
                    blob = new Blob([response.data], { type: 'text/csv' });
                }

                if (blob) {
                    // Create a URL for the blob and download the file
                    const downloadUrl = URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.href = downloadUrl;
                    anchor.download = await generateUniqueCsvFilename(); // Specify the file name for download
                    document.body.appendChild(anchor);
                    anchor.click();

                    // Clean up the anchor element
                    document.body.removeChild(anchor);

                    // Optionally, revoke the object URL to free up memory
                    URL.revokeObjectURL(downloadUrl);
                } else {
                    throw new Error('Unsupported response data type');
                }
            } else {
                throw new Error('Response is not available or invalid');
            }
        } catch (error) {
            console.error('Error downloading litigation case files:', error);
            throw error; // Optionally, rethrow the error if needed
        }
    },

    async getLitigationFileContent(action: IRequestLitiagtionFileContent, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            if (action.litigationFileContentRequest.userAlias == undefined) {
                action.litigationFileContentRequest.userAlias = "";
            }
            const data = action.litigationFileContentRequest?.caseInfo;

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/filecontent?fileName=${action.litigationFileContentRequest.fileName}&fileId=${action.litigationFileContentRequest.fileId}&userAlias=${action.litigationFileContentRequest.userAlias.toLowerCase()}`, request);

            const litigationFileContentResponse: ILitigationFileContentResponses = {};
            litigationFileContentResponse.litigationCaseFiles = response.data
            litigationFileContentResponse.embedURL = '';
            return litigationFileContentResponse;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },

    async getLitigationFileURL(action: IRequestLitiagtionFileURL, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            if (action.litigationFileURLRequest.userAlias == undefined) {
                action.litigationFileURLRequest.userAlias = "";
            }

            const url: string = `${__API_BASE_LMI_URL__}/litigation/fileurl?filePath=${action.litigationFileURLRequest.nativeFilePath}&userAlias=${action.litigationFileURLRequest.userAlias.toLowerCase()}`;
            const header: IHttpHeader = {
                "Access-Control-Allow-Origin": "*"
            }
            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken
            }

            const response: any = await httpClient.get(url, request);

            const litigationFileURLResponse: ILitigationFileContentResponses = {};
            litigationFileURLResponse.litigationFileContentResponse = '';
            litigationFileURLResponse.embedURL = response.data;
            return litigationFileURLResponse;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },
    async requestLitigationFeatureFlag(action: IRequestLitigationFeatureFlag, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            action.featureName = action?.featureName ?? "LitigationCaseFeature";
            const lmiApi = `${__FEATURE_FLAG_BASE_URL__}/FeatureFlags/${action.featureName}?userAlias=${action.loginUserAlias.toLowerCase()}`;
            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__FEATURE_FLAG_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },
    async requestCheckIsUserPartOfLitigationSGGroup(action: IRequestIsUserPartofLitigationSgGroup, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const lmiApi = `${__API_BASE_LMI_URL__}/litigation/permissions/cases/add?userAlias=${action.userAlias.toLowerCase()}`;
            const response: any = await httpClient.request({
                url: lmiApi,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },

    async requestLitigationUserQuery(action: IRequestLitigationUserQuery, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const apiEndpoint = `${__API_BASE_LMI_URL__}/litigation/projects/${action.projectNumber}/user-queries?userAlias=${action.userAlias.toLowerCase()}`;
            const response: any = await httpClient.request({
                url: apiEndpoint,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                },
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    },
    async saveLitigationUserQuery(action: IRequestLitigationSaveUserQuery, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const apiEndpoint = `${__API_BASE_LMI_URL__}/litigation/projects/${action.projectNumber}/user-queries`;
            const data = action.payload;

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.post(apiEndpoint, request);

            return response.data;
        } catch (error: any) {
            throw error;
        }
    },
    async updateLitigationUserQuery(action: IRequestLitigationUpdateUserQuery, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const apiEndpoint = `${__API_BASE_LMI_URL__}/litigation/projects/${action.projectNumber}/user-queries/${action.queryID}`;
            const data = action.payload;

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.put(apiEndpoint, request);

            return response.data;
        } catch (error: any) {
            throw error;
        }
    },
    async deleteUserQuery(action: IRequestLitigationDeleteQuery, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const apiEndpoint = `${__API_BASE_LMI_URL__}/litigation/projects/${action.projectNumber}/user-queries/${action.queryID}?userAlias=${encodeURIComponent(action.userAlias.toLowerCase())}`;


            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,

                headers: {
                    "x-correlation-id": correlationId,
                },
            };
            const response = await httpClient.delete(apiEndpoint, request);

            return response.data;
        } catch (error: any) {
            throw error;
        }
    },
    async requestLitigationSaveUserTags(action: ILitigationSaveUserTagsRequest, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const data = {
                caseFileDetails: action.litigationSaveUserTagsRequest?.caseFileDetails,
                tags: action.litigationSaveUserTagsRequest?.tags
            };

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                    "userAlias": action.litigationSaveUserTagsRequest?.userAlias.toLowerCase()
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/case/custodians/documents/tags`, request);

            const litigationSaveUserTagsResponse: ILitigationSaveUserTagsResponses = {};
            const result = response.data as any;
            litigationSaveUserTagsResponse.isSuccess = result;
            return litigationSaveUserTagsResponse;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },
    async requestClassifyCaseFiles(action: IClassifyFilesRequest, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const data = {
                caseFileDetails: action.litigationClassifyFileRequest?.caseFileDetails,
                projectDescription: action.litigationClassifyFileRequest?.projectDescription,
                projectNumber: action.litigationClassifyFileRequest?.projectNumber
            };

            const request: IHttpClientRequest = {
                resource: `${__API_BASE_LMI_URL__}`,
                accessToken,
                data,
                headers: {
                    "x-correlation-id": correlationId,
                    "userAlias": action.litigationClassifyFileRequest?.userAlias.toLowerCase()
                },
            };
            const response = await httpClient.post(`${__API_BASE_LMI_URL__}/litigation/cases/files/classify`, request);

            const result = response.data as any;
            const litigationClassifyFileResponse: ILitigationClassifyFileResponse = result;
            return litigationClassifyFileResponse;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },
    async filesClassifySummaryByUserRequest(action: IFilesClassifySummaryByUserRequest, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const caseName: string = action?.requestFilesClassifySummaryByUser?.caseName;
            const projectNumber: string = action?.requestFilesClassifySummaryByUser?.projectNumber
            const data = {
                caseName,
                projectNumber
            };
            const apiEndpoint: string = `${__API_BASE_LMI_URL__}/litigation/cases/${caseName}/files/classification/status?projectNumber=${projectNumber}`
            const response: any = await httpClient.request({
                url: apiEndpoint,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                    "userAlias": action?.requestFilesClassifySummaryByUser?.userAlias.toLowerCase()
                },
            });

            const result = response.data as any;
            const litigationClassifyFileResponse: IResponseFilesClassifySummaryByUser = result;
            return litigationClassifyFileResponse;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    },
    async fileHistoryRequest(action: IRequestFileHistory, httpClient: IHttpClient, correlationId: string) {
        try {
            const accessToken = await getLMIToken();
            const fileId: string = action?.fileHistoryRequest?.fileId;
            const userAlias: string = action?.fileHistoryRequest?.userAlias
           
            const apiEndpoint: string = `${__API_BASE_LMI_URL__}/litigation/cases/file/history?fileId=${fileId}`
            const response: any = await httpClient.request({
                url: apiEndpoint,
                resource: `${__API_BASE_LMI_RESOURCE_ID__}`,
                accessToken: accessToken,
                headers: {
                    "x-correlation-id": correlationId,
                    "userAlias": userAlias
                },
            });
 
            const result = response.data as any;
            const fileHistoryResponses: IFileHistoryResponse[] = result;
           
            return fileHistoryResponses;
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw error;
        }
    }
};


export async function generateUniqueCsvFilename(baseName: string = 'litigation_case_files') {
    // Get the current date and time
    const now = new Date();

    // Format the date and time as a string (e.g., YYYYMMDD_HHmmss)
    const formattedDateTime = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;

    // Construct the unique filename by appending the formatted date and time to the base name
    const uniqueFilename = `${baseName}_${formattedDateTime}.csv`;

    return uniqueFilename;
}

export const ProjectAPIToken = {
    async getLmiToken() {
        return await getLMIToken();
    },
    async getGraphToken() {
        return await getGraphToken();
    }
}



//This function will get lmi bearer token to call lmi api
export async function getLMIToken() {
    try {
        const lmiApiTokenUrl: string = __API_TOKEN_URL__;
        const funcCode: string = __ENV_NAME__ === 'Prod' ? __FUNCTION_CODE__ : __FUNCTION_CODE__DEV__;
        let serverURL = `${lmiApiTokenUrl}?code=ip9Gj0XZHFK8c3CMgRiKdFPYhzQ9df4lrL86nx7mNSzfAzFub0gHVA==&resourceId=${__API_BASE_LMI_RESOURCE_ID__}`;
        var response = await fetch(serverURL);
        if (!response.ok)
            throw {
                data: {
                    StatusCode: 401,
                    Message: "UnAuthorized: Error in generating token for LMI API",
                },
            };
        const lmiBearerToken: string = await response.text();
        return lmiBearerToken;
    } catch (error: any) {
        throw error;
    }
}


//This function will get graph bearer token to call graph api
export async function getGraphToken() {
    try {
        const graphApiTokenUrl: string = __API_GRAPH_TOKEN_URL__;
        const funcCode: string = __ENV_NAME__ === 'Prod' ? __GRAPH_FUNCTION_CODE__ : __GRAPH_FUNCTION_CODE__DEV__;
        let serverURL = `${graphApiTokenUrl}?code=om8wiXQhVkQhROHooHfxV7DHNIP4dso4ugbO_WieGt-XAzFu3zS7Zg==`;
        var response = await fetch(serverURL);
        if (!response.ok)
            throw {
                data: {
                    StatusCode: 401,
                    Message: "UnAuthorized: Error in generating token for Graph API",
                },
            };
        const graphBearerToken: string = await response.text();
        return graphBearerToken;
    } catch (error: any) {
        throw error;
    }
}

import { Stack } from "@fluentui/react";
import React, { useRef } from "react";

import { ISource } from "../../../../Models/Chat/ISource";
import './MessageSources.styles.css'
import OpenFile from "./OpenFile";


export interface IMessageSource {
  source?: ISource;
  isNewAnswer?: boolean;
}
const MessageSource = (messageSource: IMessageSource) => {  
  const myRefMessage = useRef(null);
  const { source } = messageSource; 
  
  return (
    <>
      {source?.documentTitle && (
        <>
          <Stack className='file-sources-container'>
            <OpenFile source={source}/>
          </Stack>
          <div ref={myRefMessage}></div>
        </>
      )}
    </>
  );
};
export default MessageSource;
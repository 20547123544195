import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import React, { useEffect, useState } from "react";
import { ChatReducerName, chatInitialState, chatReducer } from '../../../../Shared/Reducers/Chat.reducer';
import { ChatSagas } from '../../../../Shared/Sagas/Chat.sagas';
import { IChatState } from '../../../../Shared/IChatState';
import { Reducer } from "redux";
import { IUserChatLogsRequest } from "../../../../Models/Chat/IUserChatLogsRequest";
import { requestUserChatLogs } from "../../../../Shared/Actions/Chat.action";
import DisplaySpinner from "../../../../Common/Components/DisplaySpinner";
import ErrorDisplay from "../../../../Helpers/ErrorDisplay/ErrorDisplay";
import { IUserChatLogsResponse } from "../../../../Models/Chat/IUserChatLogsResponse";
import ChatLog from "./ChatLog";
import DisplayMessage from "../../../../Common/Components/DisplayMessage";
import { MessageBarType, StackItem } from "@fluentui/react";
import { LitigationContext } from "../ChatHome";
import ProgressBar from "../../../../Common/Components/ProgressBar/ProgressBar";
import { Stack } from "@fluentui/react";
const ChatLogs = () => {
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ChatReducerName, chatReducer as Reducer, [ChatSagas]);
  const { useSelector, dispatch } = reduxContext;
  const { user, projectNumber } = React.useContext(LitigationContext);
  const {
    loginUserpersonaProps,
    userChatLogsResponse,
    isRequestingChatLogs,
    isUserChatLogsResponseError,
    chatTopicDeleteResponse,
    isDeletingChatTopic,
    isEditingChatTopic,
    chatTopicEditResponse,
    userChatLogsResponseError,
  } = useSelector((state: IChatState) => state.dynamic?.[ChatReducerName] || chatInitialState);;
  const [chatLogs, setChatLogs] = useState<IUserChatLogsResponse[]>([]);
  useEffect(() => {
    const userChatLogsRequest: IUserChatLogsRequest = {
      userAlias: user?.secondaryText,
      chatLogsCount: 10,
      projectNumber:projectNumber
    };
    dispatch(requestUserChatLogs(userChatLogsRequest));
  }, []);

  useEffect(() => {
    if (chatTopicDeleteResponse?.isDeleteTopicSuccess) {
      const userChatLogsRequest: IUserChatLogsRequest = {
        chatLogsCount: 10,
        userAlias: user?.secondaryText,
        projectNumber:projectNumber
      };
      dispatch(requestUserChatLogs(userChatLogsRequest));
    }
  }, [chatTopicDeleteResponse]);

  useEffect(() => {
    if (chatTopicEditResponse?.isEditTopicSuccess) {
      const userChatLogsRequest: IUserChatLogsRequest = {
        chatLogsCount: 10,
        userAlias: user?.secondaryText,
        projectNumber:projectNumber
      };
      dispatch(requestUserChatLogs(userChatLogsRequest));
    }
  }, [chatTopicEditResponse]);

  useEffect(() => {
    if (userChatLogsResponse && Object.keys(userChatLogsResponse).length > 0 && userChatLogsResponse.length > 0) {
      setChatLogs(userChatLogsResponse);
    } else {
      setChatLogs([]);
    }
  }, [userChatLogsResponse]);

  return (
    <>
      {isRequestingChatLogs && (
        <Stack style={{ marginTop: 100, marginLeft: 'auto', marginRight: 50 }}>
          <ProgressBar progressbarText={"Loading chat history..."} />
        </Stack>
      )}
      {isDeletingChatTopic && (
        <Stack style={{ marginTop: 100, marginLeft: 'auto', marginRight: 50 }}>
          <ProgressBar progressbarText={"Deleting chat topic..."} />
        </Stack>
      )}
      {isEditingChatTopic && (
        <Stack style={{ marginTop: 100, marginLeft: 'auto', marginRight: 50 }}>
          <ProgressBar progressbarText={"Editing chat topic..."} />
        </Stack>
      )}
      {!isRequestingChatLogs && isUserChatLogsResponseError && <ErrorDisplay error={userChatLogsResponseError}/>}

      {!isRequestingChatLogs &&
        !isUserChatLogsResponseError &&
        !isDeletingChatTopic &&
        !isEditingChatTopic &&
        chatLogs.length > 0 && (
          <Stack style={{ marginLeft: 40, marginTop: 15 }}>
            {chatLogs?.map((log: IUserChatLogsResponse, index: number) => {
              return <ChatLog 
              topicId={log?.topicId} 
              topicName={log?.topicName} 
              key={index} 
              isCurrentUserTopicOwner={log?.topicUser?.toLowerCase()===user?.secondaryText?.toLowerCase()?true:false}
              questionDate={log?.requestDate}
              topicUser={log?.topicUser} />;
            })}
          </Stack>
        )}
      {!isRequestingChatLogs &&
        !isUserChatLogsResponseError &&
        !isDeletingChatTopic &&
        !isEditingChatTopic &&
        chatLogs.length === 0 && (
          <DisplayMessage
            accessabilityMessage="No chat logs are present"
            messageType={MessageBarType.warning}
            message="No chat logs are present"
          />
        )}
    </>
  );
};

export default ChatLogs;

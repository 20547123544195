import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, Dropdown, IColumn, Icon, IDetailsListStyles, MessageBarType, Selection, SelectionMode, Stack, TooltipHost } from "@fluentui/react";
import { Tree } from "@fluentui/react-components";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import React from "react";
import { Reducer } from "redux";
import DisplayMessage from "../../../../../Common/Components/DisplayMessage";
import DisplaySpinner from "../../../../../Common/Components/DisplaySpinner";
import { GenericMessages } from "../../../../../Helpers/ProjectConstants";
import { ILitigationCase, ILitigationCaseFiles } from "src/Models/ILitigationCase";
import { ILitigationFileContentRequest } from "src/Models/ILitigationCaseRequest";
import { IProjectAppState } from "../../../../../Shared/ProjectState";
import { ProjectReducerName, projectReducer, projectInitialState } from "../../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../../Shared/Sagas/Project.sagas";
import { requestLitigationFileContent, requestLitigationFileURL } from "../../../../../Shared/Actions/Project.action";
import FileContentDialog from "./FileContentDialog";
import { getFileTypeIconProps } from "@fluentui/react-file-type-icons";
import '../CaseDocumentsReview/FileExplorerStyle.css';
import { useAppContext } from './LitigationFilterContext';
import QueryDropdown from "./QueryDropdown";
import InlineDisplaySpinner from "../../../../../Common/Components/InlineDisplaySpinner";
import ClassificationProgressWebJob from "./ClassificationProgressWebJob";


export type ICaseFileDetailsListProps = {
    Cases: ILitigationCase[];
    CaseFiles?: ILitigationCaseFiles[];
    totalRecords?: number;
    isAdvanceFilterApplied: boolean;
    isLitigationChatFolderButtonClicked?: boolean;
    viewType: string;
    RefreshSearch(): void;
    onChatReferenceXClick?: () => void;
    UserPreferenceSearch(searchText: string, userViewKey: string, userFolders: string): void;
}

const CaseFilesDetailList = (caseFileDetailsListProps: ICaseFileDetailsListProps) => {
    const { Cases, CaseFiles, totalRecords, isAdvanceFilterApplied, viewType, RefreshSearch, UserPreferenceSearch } = caseFileDetailsListProps
    const { advancefilters, searchBoxText, addSelectedFiles, clearFilterContext, addSelectedCaseFiles } = useAppContext();
    const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
    const { useSelector, dispatch } = reduxContext;

    const {
        isLitigationCaseFilesResultsLoading,
        isLitigationCaseFilesResultsLoadingError,
        litigationCaseFilesResponses,
        isLitigationFilesDownloading,
        isFilesClassificationLoading
    } = useSelector((state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState);

    const { loginUserpersonaProps } = useSelector(
        (state: IProjectAppState) =>
            state.dynamic?.[ProjectReducerName] || projectInitialState
    );

    const [selectedItems, setSelectedItems] = React.useState<number>(0);
    const [fileContentTitle, setfileContentTitle] = React.useState('');
    const [spFilePath, setSpFilePath] = React.useState('');
    const [nativeFilePath, setNativeFilePath] = React.useState('');
    const [hideDialog, setHideDialog] = React.useState(true);
    const [currentlySelectedRows, setCurrentlySelectedRows] = React.useState<ILitigationCaseFiles[]>([]);
    const [clickedItem, setClickedItem] = React.useState<ILitigationCaseFiles>();
    const [detailListColumns, setDetailListColumns] = React.useState<IColumn[]>();
    const [currentCaseFiles, setCurrentCaseFiles] = React.useState<ILitigationCaseFiles[]>([]);
    const [isSorting, setIsSorting] = React.useState(false);
    //----------------Folder view start-----------
    const selectionRef = React.useRef(new Selection({
        onSelectionChanged: () => {
            const selectedItems = selectionRef.current.getSelection();
            setCurrentlySelectedRows(selectedItems as ILitigationCaseFiles[]);
            addSelectedCaseFiles(selectedItems as ILitigationCaseFiles[]);
            setSelectedItems(selectedItems.length);
            let accumulatedIds = [];
            // Use map to extract the ID from each item in selectedItems
            selectedItems.forEach((item) => {
                // Push the ID of the item into accumulatedIds
                accumulatedIds.push(item["id"]);
            });
            addSelectedFiles(accumulatedIds);
        },
    }));

    const [gridHeight, setGridHeight] = React.useState('73vh'); // Default height

    const updateGridHeight = () => {
        if (window.innerWidth > 3200) { // Example breakpoint for mobile
            setGridHeight('80vh'); // Adjust height for smaller screens
        } else if(window.innerWidth < 1500){
            setGridHeight('56vh');
            }else{
            setGridHeight('73vh'); // Default height for larger screens
        }
    };

    React.useEffect(() => {
        updateGridHeight(); // Set initial height
        window.addEventListener('resize', updateGridHeight); // Add resize event listener

        return () => {
            window.removeEventListener('resize', updateGridHeight); // Cleanup on unmount
        };
    }, []);

    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'auto', // Allow horizontal scrolling
            overflowY: 'auto', // Ensure vertical scrolling is enabled
            height: gridHeight, // Set height based on state
            display: 'block', // Ensure the root is a block element
            position: 'relative', // Positioning context for child elements
        },
        headerWrapper: {
            flex: '0 0 auto',
        },
        contentWrapper: {
            maxHeight: 'calc(74vh)', // Adjust for header height if necessary
        },
    };

    const handleSearch = (userSearchText, userViewKey, userFolders) => {
        UserPreferenceSearch(userSearchText, userViewKey, userFolders);

    };

    React.useEffect(() => {
        if (CaseFiles?.length === 0) {
            setSelectedItems(0);
            setCurrentlySelectedRows([]);
            selectionRef.current.setAllSelected(false);
        }
    }, [CaseFiles]);


    const handleCloseDialog = () => {
        setHideDialog(true);
    };

    //----------------File Explorer----------------
    const FILE_ICONS: { name: string }[] = [
        { name: 'audio' },
        { name: 'csv' },
        { name: 'docx' },
        { name: 'pdf' },
        { name: 'photo' },
        { name: 'pptx' },
        { name: 'rtf' },
        { name: 'txt' },
        { name: 'vector' },
        { name: 'vsdx' },
        { name: 'vssx' },
        { name: 'vstx' },
        { name: 'xlsx' },
    ];
    const fileIcon = (fileType: string) => {
        return {
            url: `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/16/${fileType}.svg`,
        };
    }
    React.useEffect(() => {
        setDetailListColumns(columns);
        setCurrentCaseFiles(CaseFiles);
    }, [CaseFiles]);

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = _copyAndSort(CaseFiles, currColumn.fieldName!, currColumn.isSortedDescending);
        setIsSorting(true);
        setDetailListColumns([]);
        setCurrentCaseFiles([]);
        setTimeout(() => {
            setIsSorting(false);
            setDetailListColumns(newColumns);
            setCurrentCaseFiles(newItems);
          }, 3000);
       

        //dispatch(setProjectFilterListDetails(newItems));

    }
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'File Type',
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            styles: {
                cellTitle: {
                    marginTop: 12,
                    lineHeight: 16
                },
            },
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRender: (item: ILitigationCaseFiles) => (
                <TooltipHost content={`${item.native_type} file`}>
                    <img src={fileIcon(item.native_type).url}
                        //className={classNames.fileIconImg} 
                        alt={`${item.native_type} file icon`} />
                </TooltipHost>
            ),
        },
        {
            key: 'column2',
            name: 'Extracted text file name',
            fieldName: 'metadata_storage_name',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onColumnClick: onColumnClick,
            data: 'string',
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Extracted text file name</span>
                        {/* <IconButton iconProps={{ iconName: 'Filter' }} title="Filter" style={{ marginTop: 5 }} /> */}
                    </Stack>
                </>
            }
        },
        {
            key: 'column3',
            name: 'File class',
            fieldName: 'file_class',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>File class</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column4',
            name: 'Relevance Score',
            fieldName: 'fileRelevanceScore',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Relevance score</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column5',
            name: 'Decision',
            fieldName: 'decision',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Relevant to the case</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column6',
            name: 'Justification',
            fieldName: 'justification',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Justification</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column7',
            name: 'DocumentTheme',
            fieldName: 'documentTheme',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Document theme</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column8',
            name: 'DocumentSummary',
            fieldName: 'documentSummary',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Document summary</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column9',
            name: 'Custodian',
            fieldName: 'custodian',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Custodian</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column10',
            name: 'Created date',
            fieldName: 'date',
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'date',
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Date</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column11',
            name: 'Email Subject',
            fieldName: 'email_subject',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email subject</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column12',
            name: 'Native file name',
            fieldName: 'native_file_name',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Native file name</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column13',
            name: 'Email Sender',
            fieldName: 'email_sender',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email sender</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column14',
            name: 'Email to',
            fieldName: 'email_to',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email to</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column15',
            name: 'Email cc',
            fieldName: 'email_cc',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email cc</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column16',
            name: 'Email bcc',
            fieldName: 'email_bcc',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email bcc</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column17',
            name: 'Location',
            fieldName: 'location',
            minWidth: 30,
            maxWidth: 80,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Location</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column18',
            name: 'Native extension',
            fieldName: 'native_extension',
            minWidth: 30,
            maxWidth: 80,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Native extension</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column19',
            name: 'Compound path',
            fieldName: 'compound_path',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Compound path</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column20',
            name: 'Message_kind',
            fieldName: 'message_kind',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Message kind</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column21',
            name: 'Doc authors',
            fieldName: 'doc_authors',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Doc authors</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column22',
            name: 'Email Participants',
            fieldName: 'email_Participants',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email participants</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column23',
            name: 'Email participant domains',
            fieldName: 'email_participant_domains',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email participant domains</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column24',
            name: 'Thread participants',
            fieldName: 'thread_participants',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Thread participants</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column25',
            name: 'Email sender domain',
            fieldName: 'email_sender_domain',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email sender domain</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column26',
            name: 'Email recipient domains',
            fieldName: 'email_recipient_domains',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Email recipient domains</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column27',
            name: 'ALL CUSTODIANS',
            fieldName: 'alL_CUSTODIANS',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>ALL custodians</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column28',
            name: 'True Family ID',
            fieldName: 'true_Family_ID',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>True family id</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column29',
            name: 'Set Id',
            fieldName: 'set_id',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Set id</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column30',
            name: 'Pivot id',
            fieldName: 'pivot_id',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Pivot id</span>
                    </Stack>
                </>
            }
        },
        ,
        {
            key: 'column31',
            name: 'File ID',
            fieldName: 'file_ID',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>File id</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column40',
            name: 'Original File ID',
            fieldName: 'original_File_ID',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Original file id</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column32',
            name: 'Similarity percent',
            fieldName: 'similarity_percent',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Similarity percent</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column33',
            name: 'Load id',
            fieldName: 'load_id',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Load id</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column34',
            name: 'Export Id',
            fieldName: 'export_ID',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Export id</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column35',
            name: 'Conversation Name',
            fieldName: 'conversationName',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Conversation name</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column36',
            name: 'Channel Name',
            fieldName: 'channel_Name',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Channel name</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column37',
            name: 'Team Name',
            fieldName: 'teamName',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Team name</span>
                    </Stack>
                </>
            }
        },
        {
            key: 'column38',
            name: 'Compliance labels',
            fieldName: 'compliance_labels',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>Compliance labels</span>
                    </Stack>
                </>
            }
        },
        // ,
        // {
        //     key: 'column4',
        //     name: 'Relevant to case',
        //     fieldName: 'isRelvanttoCase',
        //     minWidth: 50,
        //     maxWidth: 70,
        //     isResizable: true,
        //     onColumnClick: onColumnClick,
        //     data: 'boolean',
        //     isPadded: true,
        //     onRenderHeader: () => {
        //         return <>
        //             <Stack horizontal>
        //                 <span style={{ marginTop: 12 }}>Relevant to case</span>
        //             </Stack>
        //         </>
        //     }
        // },                  
        // {
        //     key: 'column10',
        //     name: 'Input Path',
        //     fieldName: 'input_path',
        //     minWidth: 50,
        //     maxWidth: 100,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        //     onColumnClick: onColumnClick,
        //     onRenderHeader: () => {
        //         return <>
        //             <Stack horizontal>
        //                 <span style={{ marginTop: 12 }}>Input Path</span>
        //             </Stack>
        //         </>
        //     }
        // },                
        // {
        //     key: 'column13',
        //     name: 'Email Recipients',
        //     fieldName: 'email_recipients',
        //     minWidth: 50,
        //     maxWidth: 100,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        //     onColumnClick: onColumnClick,
        //     onRenderHeader: () => {
        //         return <>
        //             <Stack horizontal>
        //                 <span style={{ marginTop: 12 }}>Email Recipients</span>
        //             </Stack>
        //         </>
        //     }
        // },
        // {
        //     key: 'column24',
        //     name: 'Tags',
        //     fieldName: 'tags',
        //     minWidth: 30,
        //     maxWidth: 100,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        //     onColumnClick: onColumnClick,
        //     onRenderHeader: () => {
        //         return <>
        //             <Stack horizontal>
        //                 <span style={{ marginTop: 12 }}>Tags</span>
        //             </Stack>
        //         </>
        //     }
        // },        
        {
            key: 'column39',
            name: 'User Tags',
            fieldName: 'user_tags',
            minWidth: 40,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            onColumnClick: onColumnClick,
            isPadded: true,
            isSorted: false,
            isSortedDescending: false,
            onRenderHeader: () => {
                return <>
                    <Stack horizontal>
                        <span style={{ marginTop: 12 }}>User tags</span>
                    </Stack>
                </>
            }
        },
        // {
        //     key: 'column28',
        //     name: 'Assigned Reviewer',
        //     fieldName: 'assignedReviewer',
        //     minWidth: 40,
        //     maxWidth: 90,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        //     onColumnClick: onColumnClick,
        //     isPadded: true,
        //     onRenderHeader: () => {
        //         return <>
        //             <Stack horizontal>
        //                 <span style={{ marginTop: 12 }}>Assigned Reviewers</span>
        //             </Stack>
        //         </>
        //     }
        // },                                     
    ];

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const onItemInvoked = (item?: any, index?: number, ev?: Event) => {
        const selectedRows = selectionRef.current.getSelection() as ILitigationCaseFiles[];

        setSelectedItems(selectedRows?.length);
        if (item) {
            setfileContentTitle(item?.metadata_storage_name);
            setSpFilePath(item?.input_path);
            setHideDialog(false);
            let nativePath = combinePaths(item.metadata_storage_path, item.export_native_path);
            setNativeFilePath(nativePath);
            setClickedItem(item);
            const caseInfo: ILitigationCase = Cases?.find(x => x.caseId === item.caseId);
            const litigationFileContentRequest: ILitigationFileContentRequest = {
                userAlias: loginUserpersonaProps?.secondaryText,
                fileName: item.metadata_storage_name,
                fileId: item.id,
                caseInfo: caseInfo,
                isKeywordSearch: false,
                nativeFilePath: nativePath
            }
            dispatch(requestLitigationFileContent(litigationFileContentRequest));
            dispatch(requestLitigationFileURL(litigationFileContentRequest));
        }
    };

    function combinePaths(metadataStoragePath: string, exportNativePath: string): string {
        // Step 1: Find the index of '.net/' in metadataStoragePath
        const baseIndex = metadataStoragePath.indexOf('.net/') + 5; // Length of '.net/' is 5

        if (baseIndex === 4) {
            console.error('Failed to find .net/ in metadataStoragePath.');
            return '';
        }

        // Find the index of the next '/' after the baseIndex
        const nextSlashIndex = metadataStoragePath.indexOf('/', baseIndex);
        if (nextSlashIndex === -1) {
            console.error('No slash found after .net/ in metadataStoragePath.');
            return '';
        }

        // Find the next '/' to extract the base URL up to and including the container name and litigation ID
        const subsequentSlashIndex = metadataStoragePath.indexOf('/', nextSlashIndex + 1);
        if (subsequentSlashIndex === -1) {
            console.error('Failed to find the next slash after container name and litigation ID.');
            return '';
        }

        // Extract the base URL up to and including the container name and litigation ID
        const baseURL = metadataStoragePath.substring(0, subsequentSlashIndex + 1);

        // Step 2: Combine the base URL with exportNativePath
        const filePath = `${baseURL}${exportNativePath.startsWith('/') ? exportNativePath.slice(1) : exportNativePath}`;

        // Step 3: Return the combined file path
        return filePath;
    }

    const [advanceFilterTextvisible, setadvanceFilterTextvisible] = React.useState(true);

    const handleClose = () => {
        caseFileDetailsListProps.onChatReferenceXClick();
    };

    return (
        <>
            <FileContentDialog
                isOpen={!hideDialog}
                title={fileContentTitle}
                onDismiss={handleCloseDialog}
                dialogWidth="98vw"
                dialogHeight="98vh"
                nativeFilePath={nativeFilePath}
                item={clickedItem}
            />
            <div style={{ backgroundColor: 'white', width: '100%', maxWidth: '100%', height: 'calc(100vh - 200px)', overflowY: 'hidden' }} >
                <Stack>
                    <Stack>
                    <Stack tokens={{ childrenGap: 14 }} style={{
                        marginTop: '7px',
                        marginLeft: '10px',
                        marginRight: '10px'
                    }} >
                        <Stack horizontal tokens={{ childrenGap: 14 }}>
                            <button
                                id="recent-my-projects-btn"
                                className={totalRecords > 0 ? "recent-button-selected all-button" : "recent-button my-projects-button"}
                                style={{ width: 150, marginTop: 5 }}
                            >
                                <div className={totalRecords > 0 ? "recent-button-text-selected" : "recent-button-text"}  style={{ minWidth: "150px" }} >
                                    Total files  ({totalRecords})
                                </div>
                            </button>
                            <button
                                id="recent-teams-projects-btn"
                                className={selectedItems > 0 ? "recent-button-selected all-button" : "recent-button my-projects-button"}
                                style={{ width: 150, marginTop: 5 }}
                            >
                                <div className={selectedItems > 0 ? "recent-button-text-selected" : "recent-button-text"} style={{ minWidth: "150px" }} >
                                    Selected files ({selectedItems})
                                </div>
                            </button>

                            <QueryDropdown onOptionClick={handleSearch} chatReferencesButtonClicked={caseFileDetailsListProps?.isLitigationChatFolderButtonClicked} />

                            {isFilesClassificationLoading && (
                                <div style={{ marginTop: 5 }}>
                                    <InlineDisplaySpinner spinnerPosition={"right"} spinnerText="Performing file classification..." accessabilityMessage="Classifying the files" />
                                </div>
                            )}                            
                            <ClassificationProgressWebJob cases={Cases}/>
                        </Stack>

                        {caseFileDetailsListProps?.isLitigationChatFolderButtonClicked && (
                            <Stack horizontalAlign="start">
                                <button
                                    id="recent-teams-projects-btn"
                                    className={"recent-button-selected all-button"}
                                    style={{ width: 150, marginTop: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    <div 
                                        className={"recent-button-text-selected"} 
                                        style={{ 
                                            minWidth: "250px", 
                                            display: 'flex', 
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'normal'
                                        }} 
                                    >
                                        Chatbot reference files
                                        <span
                                            className="close-icon"
                                            style={{ marginLeft: 5, cursor: 'pointer' }}
                                            onClick={handleClose}
                                        >
                                            X
                                        </span>
                                    </div>
                                </button>
                            </Stack>
                        )}



                            {/* {advancefilters?.length > 0 && (
                                <button
                                    id="recent-teams-projects-btn"
                                    onClick={(e) => { }}
                                    className={"recent-button-selected all-button"}
                                    style={{ width: 190 }}
                                >
                                    <div className={"recent-button-text-selected"}>
                                        Advance Filters Applied
                                        <button onClick={handleClose} style={{ fontSize: 12, marginLeft: 5 }}>
                                            X
                                        </button>
                                    </div>
                                </button>
                            )} */}
                            <div style={{ marginLeft: 'auto', marginRight: 15 }}>

                                {isLitigationFilesDownloading && (
                                    <Stack style={{ marginTop: -200 }}>
                                        <span role="alert" aria-label={"Exporting to CSV"} aria-live="assertive" />

                                        <DisplaySpinner
                                            accessabilityMessage={"Exporting to CSV"}
                                            spinnerText={"Exporting to CSV"}
                                            spinnerPosition={"right"}
                                        />
                                    </Stack>
                                )}

                            </div>
                            {/* <button
                                                id="recent-my-projects-btn"
                                                onClick={(e) => { }}
                                                className={"recent-button-selected all-button"}
                                            >
                                                <div className={"recent-button-text-selected"}>All</div>
                                            </button>
                                            <button
                                                id="recent-teams-projects-btn"
                                                onClick={(e) => { }}
                                                className={"recent-button select-files-button"}
                                            >
                                                <div className={"recent-button-text"}>Selected ({selectedItems})</div>
                                            </button>
                                            <button
                                                id="recent-teams-projects-btn"
                                                onClick={(e) => { }}
                                                className={"recent-button select-files-button"} style={{ width: 105 }}
                                            >
                                                <div className={"recent-button-text"}>Assigned to you</div>
                                            </button> */}

                        </Stack>
                    </Stack>
                    {isLitigationCaseFilesResultsLoading && (
                            <Stack style={{ marginTop: -75 }}>
                                <span role="alert" aria-label={GenericMessages.dueDateProjectsLoading} aria-live="assertive" />

                                <DisplaySpinner
                                    accessabilityMessage={"Loading Documents To Review"}
                                    spinnerText={"Loading Documents To Review..."}
                                    spinnerPosition={"right"}
                                />
                            </Stack>
                    )}
                    {isSorting && (
                            <Stack style={{ marginTop: -75 }}>
                                <span role="alert" aria-label={"Sorting Loading Documents To Review"} aria-live="assertive" />

                                <DisplaySpinner
                                    accessabilityMessage={"Sorting Loading Documents To Review"}
                                    spinnerText={"Sorting Loading Documents To Review..."}
                                    spinnerPosition={"right"}
                                />
                            </Stack>
                        )}
                    {!isLitigationCaseFilesResultsLoading && !isLitigationCaseFilesResultsLoadingError && CaseFiles && CaseFiles?.length === 0 && (
                        <>
                            <DisplayMessage accessabilityMessage="Please select atleast one custodian or enter search text to fetch the files" message="Please select atleast one custodian or enter search text to fetch the files" messageType={MessageBarType.info} />
                        </>
                    )}
                    {!isLitigationCaseFilesResultsLoading && !isSorting && !isLitigationCaseFilesResultsLoadingError && CaseFiles && CaseFiles?.length > 0 && (                              
                         <Stack>
                         <DetailsList
                            checkboxVisibility={CheckboxVisibility.always}
                            items={currentCaseFiles}
                            compact={true}
                            columns={detailListColumns}
                            selectionMode={SelectionMode.multiple}
                            setKey="multiple"
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            isHeaderVisible={true}
                            selection={selectionRef.current}
                            selectionPreservedOnEmptyClick={true}
                            onItemInvoked={onItemInvoked}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select"
                            styles={gridStyles} // Updated styles for scrollable pane
                        />
                        </Stack>
                    )}

                </Stack>
            </div>
        </>
    );
}

export default CaseFilesDetailList;
import { Stack, Icon, TooltipHost, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { truncateText, formatDateString } from "../../../../Helpers/ProjectHelper";
import { Context } from "@micro-frontend-react/employee-experience/lib/Context";
import { IEmployeeExperienceContext } from "@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext";
import { useDynamicReducer } from "@micro-frontend-react/employee-experience/lib/useDynamicReducer";
import {
  requestDeleteChatTopic,
  requestEditChatTopic,
  setTopicInContext,  
  shouldShowChatLogs,
} from "../../../../Shared/Actions/Chat.action";
import { ProjectReducerName, projectInitialState, projectReducer } from "../../../../Shared/Reducers/Project.reducer";
import { projectSagas } from "../../../../Shared/Sagas/Project.sagas";
import { Reducer } from "redux";
import { IChatTopicDeleteRequest } from "../../../../Models/Chat/IChatTopicDeleteRequest";
import { IChatTopicEditRequest } from "../../../../Models/Chat/IChatTopicEditRequest";
import {
  chatLogContainerStyles,
  ChatIconSvgTyles,
  ChatLogTextViewStyle,
  ChatLogTextEditStyle,
  ChatLogButtonContainerStyle,
  ChatLogHorizontalStyle
} from "./Styles/ChatLogStyles";
import { IProjectAppState } from "../../../../Shared/ProjectState";
import ChatSvg from "../../LitigationChat/ChatSvg";
import { LitigationContext } from "../ChatHome";
export interface IChatTopicProps {
  topicId?: string;
  topicName?: string;
  topicUser?: string;
  questionDate?: string;
  isCurrentUserTopicOwner?: boolean;
}
const ChatLog = (chatTopicProps: IChatTopicProps) => {
  const { topicId, topicName, topicUser, questionDate, isCurrentUserTopicOwner } = chatTopicProps;
  const { user, projectNumber } = React.useContext(LitigationContext);
  const reduxContext = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ProjectReducerName, projectReducer as Reducer, [projectSagas]);
  const { dispatch, useSelector } = reduxContext;
  const { loginUserpersonaProps } = useSelector(
    (state: IProjectAppState) => state.dynamic?.[ProjectReducerName] || projectInitialState
  );
  const [isMessageEditMode, setIsMessageEditMode] = useState<boolean>(false);
  const [topicText, setTopicText] = useState<string>("");
  const editQuestion = () => {
    setIsMessageEditMode(true);
  };
  const deleteChatTopic = () => {
    const chatTopicDeleteRequest: IChatTopicDeleteRequest = {
      topicId,
      userAlias: user?.secondaryText,
      projectNumber: projectNumber
    };
    dispatch(requestDeleteChatTopic(chatTopicDeleteRequest));
  };

  useEffect(() => {
    if (topicName && topicName?.length > 0) setTopicText(topicName);
  }, [topicName]);

  const updateChatTopic = () => {
    setIsMessageEditMode(false);
    const chatTopicEditRequest: IChatTopicEditRequest = {
      topicId: topicId,
      topicName: topicText,
      userAlias: user?.secondaryText,
      projectNumber: projectNumber
    };
    setTopicText(chatTopicEditRequest.topicName);
    dispatch(requestEditChatTopic(chatTopicEditRequest));
  };
  const cancelEditQuestion = () => {
    setIsMessageEditMode(false);
  };
  const showMessages = (messageId: string) => {
    dispatch(setTopicInContext(messageId, topicUser));    
    dispatch(shouldShowChatLogs(false));
  };
  const handleInputChange = React.useCallback((event: any, newValue?: string) => {
    setTopicText(event.target.value || "");
  }, []);
  return (
    <>
      <Stack styles={chatLogContainerStyles}>
        <Stack horizontal tokens={{ childrenGap: 5 }} style={{ marginTop: 10 }}>
          <Stack>
            <Stack style={ChatIconSvgTyles}>
              <ChatSvg />
            </Stack>
          </Stack>

          {!isMessageEditMode && (
            <Stack style={ChatLogTextViewStyle} horizontal>
              <Stack>
                <TooltipHost setAriaDescribedBy={true} content={topicName}>
                  <span onClick={() => showMessages(topicId)}>{truncateText(topicName, 15, 15)} </span>
                </TooltipHost>
              </Stack>
              <Stack style={{marginLeft:'auto', marginRight: 10}}>
                {formatDateString(questionDate)}
              </Stack>
            </Stack>
          )}
          {isMessageEditMode && isCurrentUserTopicOwner && (
            <Stack style={ChatLogTextEditStyle}>
              <TextField
                name="description"
                value={topicText}
                onChange={handleInputChange}
                placeholder="chat question"
                title="chat question"
                ariaLabel="the question that user has requested"
              />
            </Stack>
          )}
          {user?.secondaryText === topicUser && (
            <Stack horizontal tokens={{ childrenGap: 10 }} style={ChatLogHorizontalStyle}>
              <>
                <Stack style={ChatLogButtonContainerStyle}>
                  <span>
                    {!isMessageEditMode && <Icon iconName="Edit" onClick={editQuestion} style={{ cursor: "pointer" }} />}
                    {isMessageEditMode && (
                      <Icon
                        iconName="CheckMark"
                        onClick={() => updateChatTopic()}
                        style={{ cursor: "pointer", fontSize: 22 }}
                      />
                    )}
                  </span>
                </Stack>
                <Stack style={ChatLogButtonContainerStyle}>
                  <span>
                    {!isMessageEditMode && (
                      <Icon iconName="Delete" onClick={() => deleteChatTopic()} style={{ cursor: "pointer" }} />
                    )}
                    {isMessageEditMode && (
                      <Icon
                        iconName="ChromeClose"
                        onClick={cancelEditQuestion}
                        style={{ cursor: "pointer", fontSize: "small" }}
                      />
                    )}
                  </span>
                </Stack>
              </>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default ChatLog;
